import {IMate} from './IMate'

export interface IProfile extends IMate  {
    pwd: string,
    email: string,
}

export const EMPTY_PROFILE = {
    jid: '',
    pwd: '',
    email:'',
    name: '',
    loc:'',
    lastLocUpd:'',
    nick: '',
    icon: 'FaStar',
    iconColour: 'black',
    iconShape: 'square',
    key: '',
}
