export interface IMateDescriptor {
    nick: string,
    icon: string,
    iconColour: string,
    iconShape: string,
    key: string,
}

export const EMPTY_MATE_DESCRIPTOR:IMateDescriptor = {
    nick: '',
    icon: 'FaSmile',
    iconColour: "#000000",
    iconShape: 'circle',
    key:''
}

export interface IMate extends IMateDescriptor{
    jid: string,
    name: string,
    loc: string,
    lastLocUpd: string,
}

export const EMPTY_MATE:IMate = {
    ...EMPTY_MATE_DESCRIPTOR,
    jid: '',
    name: '',
    loc: '',
    lastLocUpd: '',
}