import * as React from 'react';
import * as MobXR from 'mobx-react';
import * as Grommet from 'grommet';
import { Map, TileLayer, Marker, Popup, Icon } from 'react-leaflet';

import {TransportStore} from'../stores/TransportStore'
import {ConfigStore} from'../stores/ConfigStore'
import {CrewsStore} from'../stores/CrewsStore'
import { Global, camelCaseToDash } from '../global/Global';
import { IMate } from '../global/IMate';
import {EMPTY_PROFILE } from '../global/IProfile';

import L from 'leaflet'

// eslint-disable-next-line
import * as EM from 'leaflet-extra-markers';
import '@fortawesome/fontawesome-free/css/all.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';

const TimeLapsed = require('timelapsed');
require( 'leaflet-extra-markers');

type State = {
  lat: number,
  lng: number,
  zoom: number,
}

class MapContainer extends React.Component <any, any> {
    readonly traStore : TransportStore;
    readonly conStore : ConfigStore;
    readonly creStore : CrewsStore;

     state:State = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
    }

    constructor(props: any) {
        super(props);
    
        this.traStore  = this.props.storesInstance.traStore
        this.conStore  = this.props.storesInstance.conStore
        this.creStore  = this.props.storesInstance.creStore;
    }

    onMarkerClick = (mapProps, map, clickEvent) => {

    }

    onInfoWindowClose = () => {

    }

    camelCaseToDash = (str) => {
      return str
          .replace(/[^a-zA-Z0-9]+/g, '-')
          .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/([0-9])([^0-9])/g, '$1-$2')
          .replace(/([^0-9])([0-9])/g, '$1-$2')
          .replace(/-+/g, '-')
          .toLowerCase();
  }

    render() {     
        
      let position:string[] = [];

      if (this.traStore.currentLocation !== Global.LOCATION_UNKNOWN) {
        position = this.traStore.currentLocation.split(',');
      }

      let myMarker:Icon = L.ExtraMarkers.icon({
        prefix: 'fas',
        icon: camelCaseToDash(this.conStore.profile.icon),
        markerColor: Global.UI_COLOURS.get(this.conStore.profile.iconColour) as any,
        shape: 'square',
      });
    
      let mates:IMate[] = []

      let markers:any = []

      this.creStore.crew.forEach((mate:IMate, index:number)=> {

        if (mate.loc !== Global.LOCATION_UNKNOWN) {

          try {
            mate.lastLocUpd = TimeLapsed.fromDateString(mate.lastLocUpd);
            mates.push(mate);
          } catch(err) {
            mate.lastLocUpd = 'unknown';
          }

          //console.log("[MapContainer] mate location: " + mate.loc);

          let iconColour:any = 'yellow';
          let iconShape:any = 'penta';

          if (this.creStore.isActiveMate(mate.jid)) {
            iconColour= Global.UI_COLOURS.get(mate.iconColour) ? Global.UI_COLOURS.get(mate.iconColour) : EMPTY_PROFILE.iconColour;
            iconShape= 'circle';
          }

          let theirMarker = L.ExtraMarkers.icon({
              prefix: 'fas',
              icon: camelCaseToDash(mate.icon),
              markerColor: iconColour,
              shape: iconShape,
            });

          markers.push(theirMarker);
        }

      })

      return (
       
        <Grommet.Box border={{size: "medium"}} round="small" >
          {position.length > 0 &&

            <Map id="mapid" center={position} zoom={this.state.zoom} style={{height: "80vh"}} >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker icon={myMarker} position={position}>
                <Popup>
                  This is me!
                </Popup>
              </Marker>

              {mates.map((mate, index) => (
                <Marker key={index} icon={markers[index]} position={mate.loc?.split(',')}>
                  <Popup>
                    {mate.nick}<br/>({mate.lastLocUpd})
                  </Popup>
                </Marker>
              ))}

            </Map>
          }
          {position.length === 0 &&
            <Grommet.Box pad="horizontal" margin="small">
              <Grommet.Text>No map yet: don't know where I am...
              <ul>
                <li>Are Location Services running?  Try turning on GPS.</li>
                <li>Does the app have permission to access Location Services?  Try resetting site location settings in browser.</li>
              </ul>
              </Grommet.Text>
            </Grommet.Box>
          }
        </Grommet.Box>
      )
  }
}

export default MobXR.inject('storesInstance')(MobXR.observer(MapContainer))
