import * as React from 'react';
import * as ReactRouter from "react-router-dom";
//import * as MobX from 'mobx';
import * as MobXR from 'mobx-react';
import * as Grommet from 'grommet';
import {Add,Sync, } from 'grommet-icons';
import dayjs from 'dayjs';

import {CrewsStore} from'../stores/CrewsStore'
import { IMate } from '../global/IMate';
import {Global} from '../global/Global'
import {TransportStore} from'../stores/TransportStore'
import {UiStore} from'../stores/UiStore'
import Notification  from '../components/Notification';
import MateEntry  from '../components/MateEntry';

class Crew extends React.Component <any, any> {
    readonly creStore : CrewsStore;
    readonly traStore : TransportStore;
    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.creStore  = this.props.storesInstance.creStore;
        this.traStore  = this.props.storesInstance.traStore;
        this.uiStore  = this.props.storesInstance.uiStore;
    }

    doRefreshStatus = () => {
        let FORCE = true;
        this.traStore.refreshCrewStatus(FORCE);
    }      

    isActive = (mate:IMate) => {
         let result = false;
         if (dayjs(mate.lastLocUpd).isAfter(dayjs().subtract(Global.STATUS_TIMEOUT_SECONDS, 'second'))) {
             result = true;
         }

         return result; 
     }

     goCreateMate = () => {
        this.uiStore.resetMessage();
        this.creStore.setCurrentMateByJid('')
        this.props.history.push({ pathname: 'crewedit', state:{create:true}})
    }
     
    render() {
        return (
            <React.Fragment>
                <Grommet.Box flex align='start' justify='start' pad="medium" gap="medium">
                    <Grommet.Box direction="row" fill="horizontal" gap="medium" justify="between" margin={{bottom:"small"}}>
                        <Grommet.Heading margin="none">Crew </Grommet.Heading>

                        <Grommet.Box direction="row" gap="small" justify="end">
                            <Grommet.Button icon={<Sync color="status-ok" />} onClick={this.doRefreshStatus} />
                            <Grommet.Button icon={<Add color='brand' />} onClick={this.goCreateMate} />
                        </Grommet.Box>
                    </Grommet.Box>

                    <Grommet.Heading color="neutral-3" level="3" margin="none">Active</Grommet.Heading>
                    {this.creStore.crew.map((mate:IMate, index) => 
                        this.isActive(mate) ? <MateEntry key={index} mate={mate} /> : null            
                    )}

                    <Grommet.Heading color="neutral-4" level="3" margin="none">Inactive</Grommet.Heading>
                    {this.creStore.crew.map((mate:IMate, index) => 
                        this.isActive(mate) ? null : <MateEntry key={index} mate={mate} /> 
                    )}                    

                </Grommet.Box>                
                <Notification />
            </React.Fragment>
        )
    }
}

export default MobXR.inject('storesInstance')(ReactRouter.withRouter(MobXR.observer(Crew)))