import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';

import {FormTrash} from 'grommet-icons';

import {TransportStore} from'../stores/TransportStore'
import {UiStore} from'../stores/UiStore'
import ChatBox from '../components/ChatBox'
    
class ChatPage extends React.Component <any, any> {

    readonly traStore : TransportStore;
    readonly uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.state = {
            showDeleteModal:false,
        };

        this.traStore  = this.props.storesInstance.traStore;
        this.uiStore  = this.props.storesInstance.uiStore
    }

    
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }      

     cleaConvo = () => {
        this.traStore.clearConversation();
        this.toggleDeleteModal();
     }


    render() {

        return (
            <Grommet.Box margin="medium" fill="horizontal" align="start" >
                <Grommet.Box direction="row" fill="horizontal" justify="between">
                    <Grommet.Heading margin="none"> Crew Broadcasts</Grommet.Heading>
                    <Grommet.Button plain icon={<FormTrash color='status-critical' />} onClick={() => {
                                        this.toggleDeleteModal();
                                    }}/>
                </Grommet.Box>

                <ChatBox/>

                {this.state.showDeleteModal && 
                    <Grommet.Layer responsive={false} position="top" onClickOutside={this.toggleDeleteModal} onEsc={this.toggleDeleteModal}>
                        <Grommet.Box pad="medium" gap="small" width="medium">
                            <Grommet.Heading level={3} margin="none">
                            Confirm Clear Conversation
                            </Grommet.Heading>
                            <Grommet.Text>Are you sure you want to delete this conversation?</Grommet.Text>
                            <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                            >

                            <Grommet.Button
                                label='Cancel'
                                onClick={this.toggleDeleteModal}
                                />
                            <Grommet.Button
                                label={
                                <Grommet.Text color="white">
                                    <strong>Delete</strong>
                                </Grommet.Text>
                                }
                                onClick={this.cleaConvo}
                                primary
                                color="status-critical"
                            />
                            </Grommet.Box>
                        </Grommet.Box>
                    </Grommet.Layer>
                }                
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(ChatPage)))