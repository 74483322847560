import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import QrReader from 'react-qr-scanner'

import {UiStore} from'../stores/UiStore'
import { Global } from '../global/Global';

class CustomQRReader extends React.Component <any, any> {
    readonly  uiStore : UiStore;

    scannerRef = React.createRef<HTMLDivElement>();

    buttonRef = React.createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);

        this.state = {
            forcedLegacyMode: false,
            openDialog: false,
        }

        this.uiStore  = this.props.storesInstance.uiStore;
    }

    openImageDialog = () => {
        let obj:QrReader = this.scannerRef.current;
        obj.openImageDialog();
    }

    forceLegacyMode = () => {
        this.setState({
            forcedLegacyMode: true,
            openDialog: true,
        })
    }

    componentDidUpdate = () => {
        if (this.state.openDialog) {
            var link = document.createElement('a');
            link.textContent = 'download image';
            link.onclick=this.openImageDialog;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);  
        }
    }

    render() {     
        //use the global camera status, unles we have explicitly asked to use a file
        let legacyMode = this.state.forcedLegacyMode || this.uiStore.hasCamera !== Global.STATUS_CAMERA;

        return (

          <Grommet.Box pad="small">
            {this.uiStore.hasCamera !== Global.STATUS_CAMERA &&
                <Grommet.Box pad="small" gap="medium">
                    <Grommet.Text>Camera unavailable, please load image of QR code.</Grommet.Text>
                    <Grommet.Button primary label="Load" onClick={this.openImageDialog} disabled ={this.props.hasCode}/>
                </Grommet.Box>
            }

            {this.uiStore.hasCamera === Global.STATUS_CAMERA &&
                <Grommet.Box pad="small" gap="medium">
                    <Grommet.Button label="Load from file" onClick={this.forceLegacyMode} disabled ={this.props.hasCode}/>
                </Grommet.Box>
            }
                <Grommet.Box pad="small" gap="medium">
                    <QrReader
                    ref={this.scannerRef}
                    facingMode="rear"
                    delay={500}
                    maxImageSize={1500}
                    style={{ height: 240, width: 320,}}
                    onError={this.props.handleError}
                    onScan={this.props.handleScan}
                    legacyMode={legacyMode}
                    />
                </Grommet.Box>

        </Grommet.Box>  
      )
  }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(CustomQRReader)))
