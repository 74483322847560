import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';

import Notification  from '../components/Notification';
import {ConfigStore, REGISTRATION_STAGE} from'../stores/ConfigStore';
import {UiStore} from'../stores/UiStore';
import { Global } from '../global/Global';
import CustomIconPicker  from '../components/CustomIconPicker';

const CryptoJS = require("crypto-js");

   
class Register extends React.Component <any, any> {
    readonly conStore : ConfigStore;
    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.state = {
            id: '',
            pwd: '',
            email:'',
            registrationToken:'',
        };   

        this.conStore  = this.props.storesInstance.conStore  
        this.uiStore  = this.props.storesInstance.uiStore;

    }

    startRegistration = () => {
        let id = CryptoJS.SHA256(Date.now().toString() + this.state.email).toString(CryptoJS.enc.Hex);
        let pwd = CryptoJS.SHA256(Date.now().toString() ).toString(CryptoJS.enc.Hex);
        this.conStore.register(id, pwd, this.state.email);
        this.setState({
            id: id,
            pwd:pwd,
        })
    }

    completeRegistration = () => {
        this.conStore.verifyRegistration(this.state.id, this.state.pwd, this.state.registrationToken);
    }

    createProfile = () => {
        let tempProfile = this.conStore.profile;
        tempProfile.key = CryptoJS.SHA256(Date.now().toString() ).toString(CryptoJS.enc.Hex);
        tempProfile.jid = this.state.id;
        tempProfile.pwd = this.state.pwd;
        tempProfile.email = this.state.email;
        
        this.conStore.profile = tempProfile;
        this.conStore.saveProfile();

        this.conStore.saveSettings();

        this.uiStore.messageMode = Global.STATUS_OK;
        this.uiStore.messageInstance = "Profile created.";

        this.props.history.push("/")
    }  
    
    iconChange = (icon) => {
        let tempProfile = this.conStore.profile;

        if (tempProfile.icon !== icon) {
            tempProfile.icon = icon;
            this.conStore.profile = tempProfile;
        }
    }

    colourChange = (colour, event) => {
        let tempProfile = this.conStore.profile;
        tempProfile.iconColour = colour.hex;
        this.conStore.profile = tempProfile;
    }    

    render() {

        return (
            <Grommet.Box flex align='start' justify='start' pad="medium" gap="medium" >
                <Grommet.Heading margin="none">Create New Account </Grommet.Heading>
                {this.conStore.regStage === REGISTRATION_STAGE.INITIAL &&
                    <React.Fragment>
                        <Grommet.Box border="bottom" margin={{bottom:"medium"}} pad={{bottom:"medium"}}> 
                            <Grommet.Text>(1) Enter email address for registration code.</Grommet.Text> {/*info box about email addresses*/}
                        </Grommet.Box>
                        <Grommet.Form
                            value={this.state}
                            onChange={nextValue => {
                                // for some reason this causes the change state warning!! Suspect strange goings-on inside grommet...
                                this.setState({
                                    ...nextValue
                                })              
                            }}
                            onSubmit={event => {
                                    this.startRegistration();
                                }
                            }
                            >
                            <Grommet.FormField name="email" htmlFor="email-id" label="Email Address">
                                <Grommet.TextInput id="email-id" name="email"  type="email"  placeholder="Email address" />
                            </Grommet.FormField>

                            <Grommet.Box
                                        as="footer"
                                        gap="small"
                                        direction="row"
                                        align="center"
                                        justify="end"
                                        pad={{ top: "medium", bottom: "small" }}
                                        >
                                <Grommet.Button type="submit" primary label="Get Code" />
                            </Grommet.Box>
                        </Grommet.Form>
                    </React.Fragment>
                }   

                {this.conStore.regStage === REGISTRATION_STAGE.REQUESTED &&
                    <React.Fragment>
                        <Grommet.Box border="bottom" margin={{bottom:"medium"}} pad={{bottom:"medium"}} > 
                            <Grommet.Text color="light-6">(1) Enter email address for registration code.</Grommet.Text> 
                            <Grommet.Box align-self="center" border={{color:"light-6"}} margin="medium" pad="medium">                             
                                <Grommet.Text  color="light-6">{this.state.email}</Grommet.Text> 
                            </Grommet.Box>
                            <Grommet.Text>(2) Enter registration code.</Grommet.Text> 
                        </Grommet.Box>

                        <Grommet.Form
                            value={this.state}
                            onChange={nextValue => {
                                // for some reason this causes the change state warning!! Suspect strange goings-on inside grommet...

                                this.setState({
                                    ...nextValue
                                })              
                            }}
                            onSubmit={event => {
                                    this.completeRegistration();
                                }
                            }
                            >
                            <Grommet.FormField name="registrationToken" htmlFor="registrationToken-id" label="Registration Code">
                                <Grommet.TextInput id="registrationToken-id" name="registrationToken"  />
                            </Grommet.FormField>

                            <Grommet.Box
                                        as="footer"
                                        gap="small"
                                        direction="row"
                                        align="center"
                                        justify="end"
                                        pad={{ top: "medium", bottom: "small" }}
                                        >
                                <Grommet.Button type="submit" primary label="Submit Code" />
                            </Grommet.Box>
                        </Grommet.Form>
                    </React.Fragment>
                }  

                {this.conStore.regStage === REGISTRATION_STAGE.VERIFIED &&  
                    <React.Fragment>
                        <Grommet.Box border="bottom" margin={{bottom:"medium"}} pad={{bottom:"medium"}}> 
                            <Grommet.Text color="light-6">(1) Enter email address for registration code.</Grommet.Text> 
                            <Grommet.Box align-self="center" border={{color:"light-6"}} margin="medium" pad="medium">                             
                                <Grommet.Text  color="light-6">{this.state.email}</Grommet.Text> 
                            </Grommet.Box>
                            <Grommet.Text color="light-6">(2) Enter registration code.</Grommet.Text> 
                            <Grommet.Box align-self="center" border={{color:"light-6"}} margin="medium" pad="medium">                             
                                <Grommet.Text  color="light-6">{this.state.registrationToken}</Grommet.Text> 
                            </Grommet.Box>                            
                            <Grommet.Text>(3) Create Profile.</Grommet.Text> 
                        </Grommet.Box>                        

                        <Grommet.Form
                            value={this.conStore.profile}
                            onChange={nextValue => {
                                // for some reason this causes the change state warning!! Suspect strange goings-on inside grommet...
                                this.conStore.profile = nextValue;               
                            }}
                            onSubmit={event => {
                                    this.createProfile();
                                }
                            }
                            >
                            
                            <Grommet.FormField name="name" htmlFor="name-id" label="Name">
                                <Grommet.TextInput id="name-id" name="name" placeholder="Your name (optional)" />
                            </Grommet.FormField>    

                            <Grommet.Box pad="medium">
                                <Grommet.Text >Map Icon</Grommet.Text>       
                                <CustomIconPicker defaultValue={this.conStore.profile}  onIconChange={this.iconChange} onColourChange={this.colourChange}/>
                            </Grommet.Box>

                            <Grommet.Box
                                        as="footer"
                                        gap="small"
                                        direction="row"
                                        align="center"
                                        justify="end"
                                        pad={{ top: "medium", bottom: "small" }}
                                        >
                                <Grommet.Button label="Reset" />
                                <Grommet.Button type="submit" primary label="Save" />
                            </Grommet.Box>
                        </Grommet.Form>                                                            
                    </React.Fragment>
                }                             

                <Notification />                                                               
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(Register)))