import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
    
class Test extends React.Component <any, any> {

    render() {

        return (
            <React.Fragment>
                <Grommet.Box flex align='start' justify='start' pad="medium" >
                    <Grommet.Heading margin="none">Test Page </Grommet.Heading>
                    <Grommet.Box border direction="row" fill="horizontal" justify="between">
                        <Grommet.Text>Foo</Grommet.Text>
                        <Grommet.Text>Bar</Grommet.Text>
                    </Grommet.Box>
                </Grommet.Box>
            </React.Fragment>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(Test)))