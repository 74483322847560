

export default abstract class AbstractDAO {

    promiseResponse = (result: any) => {
        let promise = new Promise(function(resolve, reject){
            resolve(result)
        })

        return promise;

    }
}