import { IApiResponse } from '../global/IApiResponse';
import { Global } from '../global/Global';
import AbstractDAO from './AbstractDAO'

export class ApiService extends AbstractDAO {

    register = (id:string, pwd:string, email:string) => {

        let jsonData = JSON.stringify({identifier:id, password:pwd, email:email});

        let url = `${Global.DEFAULT_API_SCHEME}://${Global.DEFAULT_API_HOST}${Global.DEFAULT_API_PORT}/api/registration/create`;

        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            return this.promiseResponse(response.json() as IApiResponse);
        }).catch(error => {
            console.log(error)
            let response:IApiResponse = {
                message: 'Error in Registration API',
                errors: [error.stack]
            };

            return this.promiseResponse(response);
        });

    }

    verifyRegistration = (id:string, pwd:string, token:string) => {

        let jsonData = JSON.stringify({identifier:id, password:pwd, token:token});

        let url = `${Global.DEFAULT_API_SCHEME}://${Global.DEFAULT_API_HOST}${Global.DEFAULT_API_PORT}/api/registration/complete`;

        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            return this.promiseResponse(response.json() as IApiResponse);
        }).catch(error => {
            let response:IApiResponse = {
                message: 'Error in Complete Registration API',
                errors: [error]
            };

            return this.promiseResponse(response);
        });

    }   
    
    deleteRegistration = (id:string, pwd:string) => {

        let jsonData = JSON.stringify({identifier:id, password:pwd});

        console.log(`Deleting ${jsonData}`)

        let url = `${Global.DEFAULT_API_SCHEME}://${Global.DEFAULT_API_HOST}${Global.DEFAULT_API_PORT}/api/registration/delete`;

        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            return this.promiseResponse(response.json() as IApiResponse);
        }).catch(error => {
            let response:IApiResponse = {
                message: 'Error in Delete Registration API',
                errors: [error]
            };

            return this.promiseResponse(response);
        });

    }      

    subscribe = (id:string, pwd:string, email:string) => {

        let jsonData = JSON.stringify({identifier:id, password:pwd, email:email});

        let url = `${Global.DEFAULT_API_SCHEME}://${Global.DEFAULT_API_HOST}${Global.DEFAULT_API_PORT}/api/subscription/create`;

        return fetch(url, {
            method: 'post',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            return this.promiseResponse(response.json() as IApiResponse);
        }).catch(error => {
            console.log(error)
            let response:IApiResponse = {
                message: 'Error in Subscription API',
                errors: [error.stack]
            };

            return this.promiseResponse(response);
        });

    }    

    unsubscribe = (id:string, pwd:string) => {

        let jsonData = JSON.stringify({identifier:id, password:pwd});

        let url = `${Global.DEFAULT_API_SCHEME}://${Global.DEFAULT_API_HOST}${Global.DEFAULT_API_PORT}/api/subscription/delete`;

        return fetch(url, {
            method: 'delete',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            return this.promiseResponse(response.json() as IApiResponse);
        }).catch(error => {
            console.log(error)
            let response:IApiResponse = {
                message: 'Error in Unsubscription API',
                errors: [error.stack]
            };

            return this.promiseResponse(response);
        });

    }       
}