import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobXR from 'mobx-react';

import * as Grommet from 'grommet';
import {Sync, LocationPin, Clock, List, } from 'grommet-icons';
import {TransportStore} from'../stores/TransportStore'
import {CrewsStore} from'../stores/CrewsStore'
import {ConfigStore} from'../stores/ConfigStore'
import {UiStore} from'../stores/UiStore'

import MapContainer from '../components/MapContainer'

const TimeLapsed = require('timelapsed');

class HomePage extends React.Component <any, any> {
    readonly traStore : TransportStore;
    readonly creStore : CrewsStore;
    readonly conStore : ConfigStore;
    readonly uiStore  : UiStore;

    constructor(props: any) {
        super(props);

        this.state = {
            showTime:false,
            showPins: false,
        }
   
        this.traStore  = this.props.storesInstance.traStore;
        this.creStore  = this.props.storesInstance.creStore;
        this.conStore  = this.props.storesInstance.conStore;
        this.uiStore   = this.props.storesInstance.uiStore;

    }
    
    doRefreshStatus = () => {
        let FORCE = true;
        this.traStore.refreshCrewStatus(FORCE);
    }

    toggleRevealTime = () => {
        this.setState(prevState => ({
            showTime: !prevState.showTime
        }));
    }

    doDropPin = () => {

    }

    toggleShowPins = () => {
        this.setState(prevState => ({
            showPins: !prevState.showPins
        }));
    }

    render() {           
        if (!this.conStore.profile.jid)   {
            return (<ReactRouter.Redirect to="/profilecreate"/>)
        } 

        let recent = this.uiStore.locationUpdateTime ? TimeLapsed.fromDateString(this.uiStore.locationUpdateTime) : 'not yet'

        return (
            <Grommet.Box width="xxlarge" justify="start"  style={{zIndex:10}} >
                <Grommet.Box direction="row" fill="horizontal" gap="medium" justify="between" pad={{"left":"small"}}>
                    <Grommet.Button plain icon={<Clock color='brand' />} alignSelf="center" 
                                            onMouseDown={ this.toggleRevealTime } 
                                            onMouseUp={ this.toggleRevealTime }
                                            onTouchStart={ this.toggleRevealTime } 
                                            onTouchEnd={ this.toggleRevealTime }
                                        />  
                    
                    <Grommet.Box direction="row" gap="xsmall" justify="end">
                       <Grommet.Button icon={<LocationPin color="status-ok" size="medium"/>} onClick={this.doDropPin} />
                        <Grommet.Button icon={<Sync color="status-ok" size="medium"/>} onClick={this.doRefreshStatus} />
                        <Grommet.Button icon={<List color="brand" size="medium"/>} onClick={this.toggleShowPins} />
                    </Grommet.Box>
                </Grommet.Box>

                {this.state.showPins && 
                    <Grommet.Box>
                        <Grommet.Heading level="3">Saved Locations</Grommet.Heading>
                    </Grommet.Box>
                }

                <MapContainer/>

                {this.state.showTime && (
                    <Grommet.Layer
                        position="top"
                        modal={false}
                        margin={{ vertical: "xlarge", horizontal: "small" }}
                        responsive={false}
                        
                    >
                        <Grommet.Box
                            align="start"
                            direction="column"
                            round="medium"
                            elevation="medium"
                            pad={{ vertical: "xsmall", horizontal: "small" }}
                        >
                            <Grommet.Heading level="3" >Last map update</Grommet.Heading>
                            <Grommet.Text alignSelf='center' > {recent}</Grommet.Text>
                        </Grommet.Box>
                    </Grommet.Layer>
                )}           

           
            </Grommet.Box>
        )
    }
}

export default MobXR.inject('storesInstance')(ReactRouter.withRouter(MobXR.observer(HomePage)))