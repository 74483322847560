import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import {  Menu, Group, Map, Announce, User, Configure, DocumentStore, Cloud, Detach} from 'grommet-icons';


import logo from './logo-32.png'

import HomePage from './pages/HomePage'
import Crew from './pages/Crew'
import CrewEdit from './pages/CrewEdit'
import Profile from './pages/Profile'
import Register from './pages/Register'
import NewPage from './pages/NewPage'
import ChatPage from './pages/ChatPage'
import Settings from './pages/Settings'
import Backup from './pages/Backup'
import Test from './pages/Test'
import TransportAdapter from './components/TransportAdapter'
import {ConfigStore} from'./stores/ConfigStore'
import {TransportStore} from'./stores/TransportStore'
import {UiStore} from'./stores/UiStore'
import { Global } from './global/Global';

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: '#228BE6',  
      focus: 'neutral-3',
    }, 
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
  formField: {
    border: {
      side: 'all',
      size: 'medium',
    },
    round:'xsmall',
  },  
});

const AppBar = (props) => (
  <Grommet.Box
    tag='header'
    direction='row'
    align='start'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
);

class App extends React.Component <any, any> {
  readonly conStore : ConfigStore;
  readonly  uiStore : UiStore;
  readonly  traStore : TransportStore;

  constructor(props: any) {
    super(props);
    this.state = {
      showSide: false
    };

    this.conStore  = this.props.storesInstance.conStore;
    this.uiStore  = this.props.storesInstance.uiStore;
    this.traStore  = this.props.storesInstance.traStore;

    this.conStore.reloadSettings();

    this.uiStore.probeCamera();

    console.log(`Running against API server ${Global.DEFAULT_API_SCHEME}://${Global.DEFAULT_API_HOST}${Global.DEFAULT_API_PORT}`)
  }

  showSidebar = () => {
    this.setState(prevState => ({
      showSide: !prevState.showSide
    }));
  }
  
  goHome = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/")
  }

  goMap = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/map")
  }

  goProfile = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/profile")
  }

  goProfileCreate = () => {
    this.props.history.push("/profilecreate")
  }
  
  goSettings = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/settings")
  }

  goCrew = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/crew")
  }

  goChat = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/chat")
  }

  goStatus = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/status")
  }

  goTest = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/test")
  }

  goBackup = () => {
    this.uiStore.resetMessage();
    this.props.history.push("/backup")
  }
  
  render() {
    let isOnline = this.traStore.statusOnline;
    
    let allowedPath = this.conStore.profileInstance.jid  || this.props.location.pathname === '/backup' || this.props.location.pathname === '/register' 
    return (
      <Grommet.Grommet theme={theme} themeMode="light" full>
        <Grommet.ResponsiveContext.Consumer>
          {size => (
            <Grommet.Box fill>
              <AppBar pad={{"top":"none", "bottom":"none", "left":"small", "right":"xsmall"}}  >
                <Grommet.Box direction="row" align="start" gap="small" alignSelf="center" >
                  <Grommet.Image src={logo}  alignSelf='center'></Grommet.Image>
                  <Grommet.Text size="medium" alignSelf="center" >W.T.F.R.U.?</Grommet.Text>

                  {isOnline &&
                    <Grommet.Box alignSelf="center">
                      <Cloud size="small"/> 
                    </Grommet.Box>
                  }
                  {!isOnline &&
                    <Grommet.Box alignSelf="center">
                      <Detach  size="small"/> 
                    </Grommet.Box>
                  }                  
                </Grommet.Box>
                <Grommet.Box direction="row" align="end" alignSelf='center' >
                  <Grommet.Button icon={<Map />} onClick={this.goHome} />
                  <Grommet.Button icon={<Announce />} onClick={this.goChat} />
                  <Grommet.Button icon={<Group />} onClick={this.goCrew} />
                
                  <Grommet.Menu
                  alignSelf='center'
                    icon={<Menu/>} 
                    dropProps={{ align: { top: "bottom", left: "left" } }}
                    items={[
                      { icon: <User />, gap: "small", label: "Profile", onClick: () => {this.goProfile()} },                      
                      { icon: <Configure />, gap: "small", label: "Settings", onClick: () => {this.goSettings()} },       
                      { icon: <DocumentStore />, gap: "small", label: "Data", onClick: () => {this.goBackup()} },
                      //{ label: "Test Page", onClick: () => {this.goTest()} },
                    ]}
                  />
                </Grommet.Box>  
              </AppBar>

              <Grommet.Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                <Grommet.Box flex direction='row'align='start' justify='start'>

                  {allowedPath &&
                    <ReactRouter.Switch>
                        <ReactRouter.Route path="/" exact render={()=><HomePage/>}/>
                        <ReactRouter.Route path="/crew" exact render={()=><Crew/>}/>
                        <ReactRouter.Route path="/crewedit" exact render={()=><CrewEdit/>}/>
                        <ReactRouter.Route path="/profile" exact render={()=><Profile/>}/>              
                        <ReactRouter.Route path="/register" exact render={()=><Register/>}/>            
                        <ReactRouter.Route path="/chat" exact render={()=><ChatPage/>}/>
                        <ReactRouter.Route path="/test" exact render={()=><Test/>}/>
                        <ReactRouter.Route path="/settings" exact render={()=><Settings/>}/>   
                        <ReactRouter.Route path="/backup" exact render={()=><Backup/>}/>                                              
                    </ReactRouter.Switch>
                  }

                  {!allowedPath &&
                    <NewPage/>
                  }
                </Grommet.Box>                
              </Grommet.Box>
            </Grommet.Box>
          )}
        </Grommet.ResponsiveContext.Consumer>
        <TransportAdapter/>
      </Grommet.Grommet>

    );
  }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(App)))