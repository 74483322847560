import * as MobX from 'mobx';
import * as MobXUtils from 'mobx-utils';
import dayjs from 'dayjs';

import {Global} from '../global/Global'
import StoresInstance from './StoresInstance';
import CrewsDAO from '../dataaccess/CrewsDAO';
import { IMate, EMPTY_MATE } from '../global/IMate';
import { IStatus} from  '../global/IStatus';


type CrewList = Record<string, {mate: IMate}>

export class CrewsStore {
    storesInstance : StoresInstance;

    crewsDao: CrewsDAO;

    crewInstance:Map<string, IMate> ;

    currentMateInstance:IMate | undefined;

    constructor(storesInstance : StoresInstance) {
        this.storesInstance = storesInstance;

        this.crewsDao = new CrewsDAO()

        this.crewInstance = new Map();

        this.reloadCrews()
    }

    get crew () {
        let mates = Array.from(this.crewInstance.values())

        mates.sort((a, b) => {
            let aString = this.getMateLabel(a);
            let bString = this.getMateLabel(b) ;
            if (aString < bString) {
                return -1;
              }
              if (aString > bString) {
                return 1;
              }            
              // names must be equal
              return 0;
        });

        return MobX.toJS(mates, {recurseEverything:true});
    }

    set crew(candidateCrew:any) {
        candidateCrew.forEach(entry => {
            if (entry.jid) {
                this.crewInstance.set(entry.jid, entry);
            }
        })

        this.crewsDao.putCrew(this.crew );
    }

    reloadCrews = () => {
        this.crewsDao.getCrew().then(result => {
            if (result) {
                let array = result as IMate[];
                array.forEach(entry => {
                    if (entry.jid) {
                        this.crewInstance.set(entry.jid, entry);
                    }
                })
            } else {
                this.crewInstance = new Map()
            }
        })
    }

    getMateByJid = MobXUtils.computedFn(function getMateByJid(this:CrewsStore, jid:string) {
        let qyeryJid = jid.split('@');

        return this.crewInstance.get(qyeryJid[0]);
    });    
    
    createOrUpdateMate = (mate:IMate) => {
        
        if (mate.jid) {
            this.crewInstance.set(mate.jid, mate);

            this.crewsDao.putCrew(this.crew );
        }
    }
    
    deleteMate = (jid:string) => {
        
        this.crewInstance.delete(jid);

        this.crewsDao.putCrew(this.crew );
    }

    updateMateStatus = (status:IStatus) => {
        //console.log(`[CrewStore] Updating status from ${status.jid}`)

        let mate:IMate | null | undefined = this.getMateByJid(status.jid);

        if(mate){
            //console.log(`Updating mate status ${status.jid} # ${status.timestamp} - ${status.loc}`)
            mate.loc = status.loc;
            mate.lastLocUpd = status.timestamp;

            this.storesInstance.uiStore.locationUpdateTime = status.timestamp;

            this.createOrUpdateMate(mate);
        }
    }
    
    isActiveMate = (jid: string | null) => {
        let result = false;

        
        if (jid) {
            let mate: IMate | undefined = this.getMateByJid(jid);
            
            try {
                if (mate && mate.lastLocUpd && dayjs(mate.lastLocUpd).isAfter(dayjs().subtract(Global.STATUS_TIMEOUT_SECONDS, 'second'))) {
                    result = true;
                }
            } catch (e) {
                //do nothing
            }
        }

        return result;
    }

    get currentMate () {
        let rawMate =  MobX.toJS(this.currentMateInstance, {recurseEverything:true});

        return rawMate ? rawMate : EMPTY_MATE;
    }

    set currentMate(mate:IMate) {
        this.currentMateInstance = mate;
    }

    setCurrentMateByJid(jid:string) {
        this.currentMateInstance = this.getMateByJid(jid);
    }

    getMateLabel(mate:IMate) {
        let result = "unknown"
        if(mate) {
            if (mate.nick) {
                result = mate.nick;
            } else if (mate.name) {
                result = mate.name;
            }
        }

        return result;
    }

    clearData = () => {
        this.currentMateInstance = EMPTY_MATE;

        this.crewInstance = new Map();
    }
}

MobX.decorate (
    CrewsStore, {
        crewInstance: MobX.observable,
        currentMateInstance: MobX.observable,
      
        reloadCrews: MobX.action,
        updateMateStatus: MobX.action,
        createOrUpdateMate: MobX.action,
        deleteMate: MobX.action,
        setCurrentMateByJid: MobX.action,
        clearData: MobX.action,
    }
)
