import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import { FormEdit, FormTrash} from 'grommet-icons';

import { Global, camelCaseToDash } from '../global/Global';

import {CrewsStore} from'../stores/CrewsStore'
import {UiStore} from'../stores/UiStore'
import StatusIcon from './StatusIcon'

const TimeLapsed = require('timelapsed');
   
class MateEntry extends React.Component <any, any> {

    readonly  uiStore : UiStore;
    readonly creStore : CrewsStore;

    constructor(props: any) {
        super(props);

        this.state = {
            showDeleteModal:false,
        };

        this.creStore  = this.props.storesInstance.creStore;

        this.uiStore  = this.props.storesInstance.uiStore;
    }

    goEditCrew = () => {
        this.uiStore.resetMessage();
        this.creStore.setCurrentMateByJid(this.props.mate.jid)
        this.props.history.push({ pathname: 'crewedit', state:{create:false}})
    }

    toggleDeleteModal = () => {
        this.uiStore.resetMessage();
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }   

     deleteMate = () => {
         let tempMate = this.creStore.getMateByJid(this.props.mate.jid);
         let label:string = tempMate ? this.creStore.getMateLabel(tempMate) : "<unknown";

        this.creStore.deleteMate(this.props.mate.jid);
        this.toggleDeleteModal();

        this.uiStore.messageMode = Global.STATUS_OK;
        this.uiStore.messageInstance =  "Mate '" + label + "' deleted.";
     }

    render() {

        return (
            <React.Fragment>
                <Grommet.Box key={this.props.mate.jid} fill margin={{bottom:"medium"}}>
                    <Grommet.Box direction="row" fill="horizontal" justify="between" >
                        <Grommet.Text weight='bold'><span className={`fas ${camelCaseToDash(this.props.mate.icon)}`} style={{ color: this.props.mate.iconColour,  fontSize: "arge",}}/> {this.props.mate.nick ? this.props.mate.nick : "(no nickname)"}</Grommet.Text>
                        <Grommet.Box direction="row" gridArea="buttons" justify="end">
                            <Grommet.Button plain size="small" icon={<FormEdit color='brand'/>} onClick={this.goEditCrew} />
                            <Grommet.Button plain size="small" icon={<FormTrash color='status-critical' />} onClick={this.toggleDeleteModal}/>
                        </Grommet.Box> 
                    </Grommet.Box>

                    <Grommet.Box direction="row" gap="small">                    
                        <Grommet.Text >{this.props.mate.name}</Grommet.Text>
                        <StatusIcon mate={this.props.mate}/>
                        <Grommet.Text alignSelf="center" size="small">({this.props.mate.lastLocUpd ? TimeLapsed.fromDateString(this.props.mate.lastLocUpd) : "Not Updated"})</Grommet.Text>

                    </Grommet.Box>  
                </Grommet.Box> 
                {this.state.showDeleteModal && 
                    <Grommet.Layer responsive={false} position="top" onClickOutside={this.toggleDeleteModal} onEsc={this.toggleDeleteModal}>
                        <Grommet.Box pad="medium" gap="small" width="medium">
                            <Grommet.Heading level={3} margin="none">
                            Confirm Delete Mate 
                            </Grommet.Heading>
                            <Grommet.Text>Are you sure you want to delete mate {this.creStore.getMateLabel(this.props.mate.jid)}?</Grommet.Text>
                            <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                            >

                            <Grommet.Button
                                label='Cancel'
                                onClick={this.toggleDeleteModal}
                                />
                            <Grommet.Button
                                label={
                                <Grommet.Text color="white">
                                    <strong>Delete</strong>
                                </Grommet.Text>
                                }
                                onClick={this.deleteMate}
                                primary
                                color="status-critical"
                            />
                            </Grommet.Box>
                        </Grommet.Box>
                    </Grommet.Layer>
                }  
            </React.Fragment>                          
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(MateEntry)))

    