import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import {StatusInfo} from 'grommet-icons';

import {CrewsStore} from'../stores/CrewsStore'
import CustomIconPicker from '../components/CustomIconPicker';
import CustomQrReader from '../components/CustomQrReader';
import {UiStore} from'../stores/UiStore'
import Notification  from '../components/Notification';
import {Global} from '../global/Global'
import { IMate, EMPTY_MATE } from '../global/IMate';

var CryptoJS = require("crypto-js");

class CrewEdit extends React.Component <any, any> {
    readonly creStore : CrewsStore;
    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);
    
        this.creStore  = this.props.storesInstance.creStore
        this.uiStore  = this.props.storesInstance.uiStore;

        this.state = {
            descriptor: null,
            showQRModal: this.props.location.state.create ? true: false,
            QRString: '',
            pin:'',
            decodeMessage:'',
            showJid:false,
        };
    }

    goCrew = () => {
        this.uiStore.resetMessage();
        this.props.history.push("/crew")
    }

    iconChange = (icon) => {
        let tempMate = this.creStore.currentMate;

        if (tempMate.icon !== icon) {
            tempMate.icon = icon;
            this.creStore.currentMate = tempMate;
        }
    }

    colourChange = (colour) => {
        let tempMate = this.creStore.currentMate;

        if (tempMate.iconColour !== colour.hex) {
            tempMate.iconColour = colour.hex;
            this.creStore.currentMate = tempMate;
        }        
    }    

    saveMate = () => {
        this.creStore.createOrUpdateMate(this.creStore.currentMate);
        this.uiStore.messageMode = Global.STATUS_OK;
        this.uiStore.messageInstance =  "Mate '" + this.creStore.getMateLabel(this.creStore.currentMate) + "' updated.";
    }

    toggleQRModal = () => {
        this.setState(prevState => ({
            showQRModal: !prevState.showQRModal
        }));

        //check for returning to crew
    } 

    handleCancel = (err) => {
        this.toggleQRModal();
        this.goCrew();
    }

    handleScan = (data) =>{
        //console.log("QR data is" + data)

        if (!this.state.QRString) {
            this.setState({
                QRString: data,
            })
        }
    }
    
    handleError = (err) => {
        console.log("QR error is" + err)
    }

    doDecode = () => {
        try {
            let ciphertextString = this.state.QRString;
            let plaintextBytes = CryptoJS.AES.decrypt(ciphertextString, "K" + this.state.pin );  

            let plaintextString = CryptoJS.enc.Utf8.stringify(plaintextBytes)

            let temp:any = JSON.parse(plaintextString);
            let newMate:IMate = EMPTY_MATE;
            
            newMate.jid = temp.jid;
            newMate.name = temp.name;
            newMate.key = temp.key;

            this.creStore.createOrUpdateMate(newMate);

            this.toggleQRModal();

        } catch(e) {
            this.uiStore.messageMode = Global.STATUS_ERR
            this.uiStore.messageInstance = "PIN does not match QR Code.";
        }    
    }

    handlePinChange = (event) => {
        this.setState({
            pin: event.target.value
        });
    }

    toggleRevealJid = () => {
        this.setState(prevState => ({
            showJid: !prevState.showJid
        }));

        //check for returning to crew
    }

    render() {

        let hasCode = this.state.QRString ? true : false
        let text1 = hasCode ? '(1) Mate details captured from QR code' : '(1) Capture Mate from QR code' ;

        return (
            <Grommet.Box flex align='start' justify='start'  pad="medium" >
                <Grommet.Heading margin="none">Update Mate </Grommet.Heading>
                <Grommet.Form
                    value={this.creStore.currentMate}
                    onChange={nextValue => { 
                        this.creStore.currentMate = nextValue;           
                    }}
                    onSubmit={event => {
                        this.saveMate();
                        setTimeout(() => {this.goCrew();  }, 2000);
                    }}
                >
                    <Grommet.Box direction="row" gap="medium">
                        <Grommet.Heading level="2" >{this.creStore.getMateLabel(this.creStore.currentMate)}</Grommet.Heading>
                        
                        <Grommet.Button plain icon={<StatusInfo color='status-ok' size="small" />} alignSelf="center"
                                        onMouseDown={ this.toggleRevealJid } 
                                        onMouseUp={ this.toggleRevealJid }
                                        onTouchStart={ this.toggleRevealJid } 
                                        onTouchEnd={ this.toggleRevealJid }
                                        />  
                    </Grommet.Box>
                    <Grommet.FormField name="nick" htmlFor="nick-id" label="Nickname">
                        <Grommet.TextInput id="nick-id" name="nick" />
                    </Grommet.FormField>

                    <Grommet.Box pad="medium">
                        <Grommet.Text >Map Icon</Grommet.Text>       
                        <CustomIconPicker defaultValue={this.creStore.currentMate}  onIconChange={this.iconChange} onColourChange={this.colourChange}/>

                    </Grommet.Box>

                    <Grommet.Box direction="row" gap="medium">
                        <Grommet.Button label="Cancel" onClick={() => {
                            this.goCrew();
                        }} />
                        <Grommet.Button type="submit" primary label="Save" />
                    </Grommet.Box>
                </Grommet.Form>
                <Notification />
                {this.state.showQRModal && 
                    <Grommet.Layer responsive={false} position="top" onClickOutside={this.toggleQRModal} onEsc={this.toggleQRModal}  margin={{vertical:"xlarge"}}>
                        <Grommet.Box pad="medium" width="medium">
                            <Grommet.Heading level={3} margin="none"> Receive Mate </Grommet.Heading>
                            <Grommet.Text>{text1}</Grommet.Text>
                            {this.state.QRString && 
                                <Grommet.Text>(2) Decode Mate with PIN.</Grommet.Text>
                            }                            
                            <Grommet.Box alignSelf="center" pad="small" gap="medium" >
                                {!this.state.QRString && 

                                        <CustomQrReader handleScan={this.handleScan} handleError={this.handleError} hasCode={hasCode}/>
                                }                                

                                {this.state.QRString && 
                                    <Grommet.Box  direction="row" align="center" justify="center" gap="medium">
                                        <Grommet.Text>PIN </Grommet.Text>
                                        <Grommet.TextInput size="large" value={this.state.pin} onChange={this.handlePinChange}/>
                                        <Grommet.Button primary label="Decode" onClick={this.doDecode}/>                     
                                    </Grommet.Box>
                                }
                            </Grommet.Box>
                            <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                            >

                            <Grommet.Button
                                label='Cancel'
                                onClick={this.handleCancel}
                                />
                            </Grommet.Box>
                        </Grommet.Box>
                    </Grommet.Layer>
                }  

                {this.state.showJid && (
                    <Grommet.Layer
                        position="top"
                        modal={false}
                        margin={{ vertical: "xlarge", horizontal: "small" }}
                        responsive={false}
                        
                    >
                        <Grommet.Box
                            align="start"
                            direction="column"
                            round="medium"
                            elevation="medium"
                            pad={{ vertical: "xsmall", horizontal: "small" }}
                        >
                            <Grommet.Heading level="3" >{this.creStore.getMateLabel(this.creStore.currentMate)}</Grommet.Heading>
                            <Grommet.Text wordBreak="break-all" >{this.creStore.currentMate.jid}</Grommet.Text>
                        </Grommet.Box>
                    </Grommet.Layer>
                )}                
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(CrewEdit)))