import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import dayjs from 'dayjs'
import { FilePicker } from 'react-file-picker'

import {Global} from '../global/Global'
import {CrewsStore} from'../stores/CrewsStore'
import {TransportStore} from'../stores/TransportStore'
import {UiStore} from'../stores/UiStore'
import {ConfigStore} from'../stores/ConfigStore';
import Notification  from '../components/Notification';

const fileDownload = require('js-file-download');

class Backup extends React.Component <any, any> {
    readonly creStore : CrewsStore;
    readonly traStore : TransportStore;
    readonly  uiStore : UiStore;
    readonly conStore : ConfigStore;

    constructor(props: any) {
        super(props);

        this.state = {
            showRestoreModal:false,
            showClearModal:false,
            removeAccount: false,
        };

        this.creStore  = this.props.storesInstance.creStore;
        this.traStore  = this.props.storesInstance.traStore;
        this.uiStore  = this.props.storesInstance.uiStore;
        this.conStore  = this.props.storesInstance.conStore  
    }

    doBackup = () => {
        let backupObj:any = {}

        backupObj.profile = this.conStore.profile;
        backupObj.settings = this.conStore.settings;
        backupObj.crew = this.creStore.crew;
        backupObj.chat = this.traStore.chat;

        let backupData = JSON.stringify(backupObj);
        let fileName = `wtfru-backup-${this.conStore.profile.jid.substring(0,4)}-${dayjs().format(Global.FILENAME_TIMESTAMP_FORMAT)}.json`;
        fileDownload(backupData, fileName);
    }

    toggleRestoreModal = () => {
        this.setState(prevState => ({
            showRestoreModal: !prevState.showRestoreModal
        }));
    }      

    toggleClearModal = () => {
        this.setState(prevState => ({
            showClearModal: !prevState.showClearModal
        }));
    }   

    toggleRemoveAccount = () => {
        this.setState(prevState => ({
            removeAccount: !prevState.removeAccount
        }));
    }   

    doRestore = (file) => {
        this.toggleRestoreModal();
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            try {
                let result:string = event && event.target ? event.target.result as string : '';
                
                let restoreObject:any = JSON.parse(result);

                this.conStore.profile = restoreObject.profile;
                this.conStore.saveProfile();
                this.conStore.settings = restoreObject.settings;
                this.conStore.saveSettings();
                this.traStore.chat = restoreObject.chat;
                this.creStore.crew = restoreObject.crew;

                this.uiStore.messageMode = Global.STATUS_OK;
                this.uiStore.messageInstance =  "Settings Restored from backup file";

                this.props.history.push("/")
            } catch (e) {
                this.uiStore.messageMode = Global.STATUS_ERR;
                this.uiStore.messageInstance =  "Error restoring from backup file";
            }
        });
        reader.readAsText(file);
    }

    doClear = () => {
        if(this.state.removeAccount) {
            this.conStore.deleteRegistration();
        }

        this.conStore.clearData();
        this.creStore.clearData();
        this.traStore.clearData();        

        this.toggleClearModal();
    }

    render() {

        return (
            <Grommet.Box direction="column" gap="small">
                <Grommet.Box align='start' justify='start' pad="medium" gap="small" border={{side:"bottom"}}>
                    <Grommet.Heading margin="none">Data Store</Grommet.Heading>
                    <Grommet.Text>Your app settings are only stored on your device.  Backup your data - for example, in case you need to move to a new device.</Grommet.Text>
                    
                    <Grommet.Button
                                    primary   
                                    label='Create Backup'
                                    onClick={this.doBackup}
                                    disabled={this.conStore.profile.jid ? false : true}
                                    />
                </Grommet.Box>

                <Grommet.Box align='start' justify='start' pad="medium" gap="small" border={{side:"bottom"}}>
                    <Grommet.Text>Restore all of your settings on this device from a backup file.</Grommet.Text>
                    
                    <Grommet.Button
                                    primary   
                                    label='Restore from Backup'
                                    onClick={this.toggleRestoreModal}
                                    />
                </Grommet.Box>

                <Grommet.Box align='start' justify='start' pad="medium" gap="small" border={{side:"bottom"}}>
                    <Grommet.Text>Remove all app data from this device.</Grommet.Text>
                    
                    <Grommet.CheckBox
                        checked={this.state.removeAccount}
                        label="Also remove account from the server"
                        reverse={true}
                        toggle={true}
                        onChange={this.toggleRemoveAccount}
                        />
                    <Grommet.Button
                                    primary   
                                    label='Clear Data'
                                    onClick={this.toggleClearModal}
                                    disabled={this.conStore.profile.jid ? false : true}
                                    />


                </Grommet.Box>               
         
                <Notification />  

                {this.state.showRestoreModal && 
                    <Grommet.Layer responsive={false} position="top" onClickOutside={this.toggleRestoreModal} onEsc={this.toggleRestoreModal} margin={{vertical:"xlarge"}}>
                        <Grommet.Box pad="medium" gap="small" width="medium" >
                            <Grommet.Heading level={3} margin="none">
                            Restore from backup
                            </Grommet.Heading>
                            <Grommet.Text>Are you sure you want to replace all data from a backup copy?</Grommet.Text>

                            <FilePicker

                                onChange={this.doRestore}
/*                                 onError={errMsg => ()} */
                            >
                                <Grommet.Button
                                    label={
                                    <Grommet.Text color="white">
                                        <strong>Restore</strong>
                                    </Grommet.Text>
                                    }
                                    primary                                   
                                />
                            </FilePicker>

                            <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                            >

                                <Grommet.Button
                                    label='Cancel'
                                    onClick={this.toggleRestoreModal}
                                    />
                            </Grommet.Box>
                        </Grommet.Box>
                    </Grommet.Layer>
                }      

                {this.state.showClearModal && 
                    <Grommet.Layer responsive={false} position="top" onClickOutside={this.toggleClearModal} onEsc={this.toggleClearModal}>
                        <Grommet.Box pad="medium" gap="small" width="medium">

                            { !this.state.removeAccount &&
                                <React.Fragment>
                                    <Grommet.Heading level={3} margin="none">
                                    Confirm Remove Data From this Device
                                    </Grommet.Heading>                                    
                                    <Grommet.Text>Are you sure you want to remove app data from this device? </Grommet.Text>
                                    <Grommet.Text margin={{top:'medium'}}>You will only be able to recover this data if you have made a backup!</Grommet.Text>
                                </React.Fragment>
                            }

                            { this.state.removeAccount &&
                                <React.Fragment>
                                    <Grommet.Heading level={3} margin="none">
                                    Confirm Remove All Data
                                    </Grommet.Heading>                                     
                                    <Grommet.Text>Are you sure you want to remove app data from this device and delete your account? </Grommet.Text>
                                    <Grommet.Text weight="bold" margin={{top:'medium'}}>You will not be able to recover a deleted account!</Grommet.Text>
                                </React.Fragment>
                            }                            

                            <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                            >

                            <Grommet.Button
                                label='Cancel'
                                onClick={this.toggleClearModal}
                                />
                            <Grommet.Button
                                label={
                                <Grommet.Text color="white">
                                    <strong>Clear app data</strong>
                                </Grommet.Text>
                                }
                                onClick={this.doClear}
                                primary
                                color="status-critical"
                            />
                            </Grommet.Box> 
                        </Grommet.Box>
                    </Grommet.Layer>
                }  
                                                    
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(Backup)))