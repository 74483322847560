import * as MobX from 'mobx';
import * as MobxUtils from 'mobx-utils';
import dayjs from 'dayjs'
import StoresInstance from './StoresInstance';
import TransportService from '../dataaccess/TransportService';
import TransportDao from '../dataaccess/TransportDAO';
import LocationService from '../dataaccess/LocationService';
import {ConfigStore} from'../stores/ConfigStore'
import { IProfile } from '../global/IProfile';
import { IMate } from '../global/IMate';
import {Global, ShutdownHandler} from '../global/Global'
import { IMessage, EMPTY_MESSAGE, 
    MSG_TYPE_UNKNOWN_TYPE, 
    MSG_TYPE_STATUS_QUERY, 
    MSG_TYPE_STATUS_RESPONSE, 

    MSG_TYPE_ECHO_QUERY, 
    MSG_TYPE_ECHO_RESPONSE,

    MSG_TYPE_CHAT,
    MSG_TYPE_CHAT_ACK,    
} from '../global/IMessage';

import { IStatus, EMPTY_STATUS} from  '../global/IStatus';

const CryptoJS = require("crypto-js");

export class TransportStore {
    storesInstance : StoresInstance;
    conStore : ConfigStore;
    transportService : TransportService;
    transportDao: TransportDao;

    statusOnline:boolean;

    locationService : LocationService;

    messageQueue : IMessage[];
    messageInstance:IMessage;

    conversationInstance : IMessage[];

    currentLocation: string;

    messageProcessorHandle:MobxUtils.IDisposer;

    timerHandle;

    constructor(storesInstance : StoresInstance) {
        this.storesInstance = storesInstance;

        this.transportService = new TransportService();

        this.transportDao = new TransportDao();
        this.locationService = new LocationService();
        this.conStore  = storesInstance.conStore             

        this.messageInstance = EMPTY_MESSAGE;

        this.currentLocation = Global.LOCATION_UNKNOWN;

        this.statusOnline = false;

        //Make TS happy
        this.conversationInstance = []
        this.reloadChat()

        //Start the message queue processor 
        this.messageQueue = []
        this.messageProcessorHandle = MobxUtils.queueProcessor(this.messageQueue, this.processMessage)

        this.resetRefreshIntervalTimer();

        ShutdownHandler.addShutdownHook(()=>{
            this.statusOnline = false;
            console.log("Pausing regular crew refresh.");
            if (this.timerHandle > 0) {
                clearTimeout(this.timerHandle);
            }
        });  

        ShutdownHandler.addShutdownHook(()=>{
            this.statusOnline = false;
            console.log("Pausing transport message queue processor.");
            this.messageProcessorHandle();
        });

        ShutdownHandler.addShutdownHook(()=>{
            this.statusOnline = false;
            console.log("Logging off from mesaging server.");
            this.transportService.doDisconnect();
        });    
    }

    resetRefreshIntervalTimer = () => {
        if (this.timerHandle > 0) {
            clearTimeout(this.timerHandle);
        }
        //start the crew refresh timer in milliseconds
        this.timerHandle = setInterval(this.refreshCrewStatus, this.conStore.settings.refreshInterval * 1000);
    }

    connectTransportService = (profile: IProfile) => {        
        this.transportService.createClient(profile, this.conStore.settings.host, this.conStore.settings.port);
        

        this.transportService.setHandleOnMessage(this.handleMessageCallback);
        this.transportService.setHandleOnChat(this.handleChatCallback);
        this.transportService.setHandleOnConnect(this.handleConnectCallback);

        this.transportService.setHandleOnConnectionFailure(this.handleOnConnectionFailureCallback);

        this.transportService.doReconnect();
    }

    connectLocationService = () => {
        this.locationService.resetLocationWatch();

        let positionOptions:  PositionOptions = {
            enableHighAccuracy: true, 
            maximumAge: this.conStore.settings.locationAge * 1000, 
            timeout: this.conStore.settings.locationTimeout * 1000, 
        };


        this.locationService.monitorLocation(this.handleLocationCallback, this.handleLocationError, positionOptions)
    }

    handleLocationCallback = (position:Position) => {
        try {
            let lat = this.conStore.settings ? Number(position.coords.latitude)  + Number(this.conStore.settings.latOffset) : position.coords.latitude;
            let long = this.conStore.settings ? Number(position.coords.longitude)  + Number(this.conStore.settings.longOffset) : position.coords.longitude;
            //console.log('[Transport Store] My location:' + lat + "," + long)
            
            this.currentLocation = lat + "," + long;



            this.storesInstance.uiStore.locationUpdateTime = dayjs().format(Global.TIMESTAMP_FORMAT);
        } catch (err) {
            console.log(`Error setting current location`)
        }
    }

    handleLocationError = (error:string) => {
        console.log(error);
    }

    handleConnectCallback = () => {
        this.statusOnline = true;
    }

    handleOnConnectionFailureCallback = () => {
        this.statusOnline = false;
    }    

    handleMessageCallback = ( msg: any) => {
        //console.log(msg)

        if (msg.type === 'error') {
            return
        }

        let incoming:IMessage = EMPTY_MESSAGE;

        try {
            let jidParts = msg.from.split('/');
            let mate = this.storesInstance.creStore.getMateByJid(jidParts[0]);
            let key = mate ? mate.key: '';

            let ciphertextString = msg.body;

            let plaintextBytes = CryptoJS.AES.decrypt(ciphertextString, "K" + key );  
            let plaintextString = CryptoJS.enc.Utf8.stringify(plaintextBytes)

            incoming = JSON.parse(plaintextString);
            incoming.fromJid = msg.from;
            incoming.toJid = this.storesInstance.conStore.profile.jid;
            incoming.recvStamp = dayjs().format(Global.TIMESTAMP_FORMAT);
        }
        catch(err) {
            //couldn't parse - most likely plain text message
            incoming.fromJid = msg.from;
            incoming.toJid = this.storesInstance.conStore.profile.jid;
            incoming.recvStamp = dayjs().format(Global.TIMESTAMP_FORMAT);
            incoming.type = MSG_TYPE_UNKNOWN_TYPE
            incoming.msgBody = msg.body;
        }

        this.messageQueue.push(incoming);
    }   

    processMessage = (incoming:IMessage) => {
        switch (incoming.type) {
            case MSG_TYPE_ECHO_QUERY: {
                let response:IMessage = this.createMessage(MSG_TYPE_ECHO_RESPONSE, 'Echo response:' + incoming.msgBody)
                this.sendMessage(response, incoming.fromJid);
            };
            break;        

            case MSG_TYPE_STATUS_QUERY: {
                let status:IStatus = {
                    jid: this.storesInstance.conStore.profile.jid,
                    loc: this.currentLocation,
                    timestamp: dayjs().format(Global.TIMESTAMP_FORMAT),
                }

                let message = this.createMessage(MSG_TYPE_STATUS_RESPONSE, JSON.stringify(status));

                this.sendMessage(message, incoming.fromJid);
            };
            break;

            case MSG_TYPE_STATUS_RESPONSE: {
                let status:IStatus = EMPTY_STATUS;

                try {
                    status = JSON.parse(incoming.msgBody);
                    this.storesInstance.creStore.updateMateStatus(status);
                } catch(err) {
                    //couldn't parse - most likely plain text message
                    console.log ('Bad status: ' + incoming.msgBody)
                }
            };
            break;  
            
            case MSG_TYPE_CHAT: 
                this.appendChat(incoming);       
            break;    
            
            case MSG_TYPE_CHAT_ACK: 
                this.markChatSeen(incoming);       
            break;            
        }
    }

    handleChatCallback = (msg:any) => {
        this.handleMessageCallback(msg)
    }

    sendMessage = (message:IMessage, to:string = message.toJid) => {

        //console.log(`[TransportStore] Sending message to ${message.toJid}: ${message.msgBody}`)


        let key = this.conStore.profile.key;

        let plaintext = JSON.stringify(message);
        let ciphertextBytes = CryptoJS.AES.encrypt(plaintext, "K" + key );       
        let ciphertextString = ciphertextBytes.toString()

        this.transportService.sendMessage(to,  ciphertextString);
    }   

    createMessage = (type:string, msgBody:string) => {
        let result:IMessage = EMPTY_MESSAGE;

        result.id = CryptoJS.SHA256(Date.now().toString() ).toString(CryptoJS.enc.Hex);
        result.fromJid = this.storesInstance.conStore.profile.jid;
        result.sendStamp = dayjs().format(Global.TIMESTAMP_FORMAT);
        result.type = type;
        result.msgBody = msgBody;

        return result;
    }

    refreshCrewStatus = (force:boolean = false) => {

        this.storesInstance.creStore.crew.forEach((mate:IMate) => {
            //console.log(`[TransportStore] Checking ${mate.jid} for refresh`)

            let statusRequestMessage:IMessage = EMPTY_MESSAGE;

            //console.log(`[TransportStore] Force= ${force} `)
            //console.log(`[TransportStore] Last Update = ${mate.lastLocUpd}`)
            //console.log(`[TransportStore] Date check = ${dayjs(mate.lastLocUpd).isBefore(dayjs().subtract(Global.STATUS_TIMEOUT_SECONDS, 'second'))}`)

            if (force || !mate.lastLocUpd || dayjs(mate.lastLocUpd).isBefore(dayjs().subtract(Global.STATUS_TIMEOUT_SECONDS, 'second'))) {            
                //console.log(`[TransportStore] Refreshing ${mate.jid}`)

                statusRequestMessage.fromJid = this.storesInstance.conStore.profile.jid;
                statusRequestMessage.toJid  = mate.jid;            
                statusRequestMessage.sendStamp = dayjs().format(Global.TIMESTAMP_FORMAT);
                statusRequestMessage.type = MSG_TYPE_STATUS_QUERY;
                statusRequestMessage.msgBody = MSG_TYPE_STATUS_QUERY;

                this.sendMessage(statusRequestMessage);
            }
        })
    }  
    
    reloadChat = () => {
        this.transportDao.getChat().then(result => {
            if (result) {
                let array = result as IMessage[];
                array.forEach(entry => {
                    if (entry.fromJid) {
                        this.conversationInstance.push(entry);
                    }
                })
            } else {
                this.conversationInstance = []
            }
        })
    }

    get chat () {
        return MobX.toJS(this.conversationInstance, {recurseEverything:true});
    }

    set chat(convo) {
        this.conversationInstance = convo;
        this.transportDao.putChat(this.chat);  
    }

    appendChat = (message: IMessage) => {
        this.conversationInstance.push(message);

        if (this.conversationInstance.length > this.conStore.settings.messageLimit) {
            this.conversationInstance.shift();
        }

        this.transportDao.putChat(this.chat);  

        let mate = this.storesInstance.creStore.getMateByJid(message.fromJid);

        if (mate) {
            const title = 'Message from '  + this.storesInstance.creStore.getMateLabel(mate )
            const body = message.msgBody;
            const tag = Global.BROWSER_NOTIFICATION_TAG_MSG;

            this.storesInstance.uiStore.triggerBrowserNotification(title, body, tag);
        }

    }

    markChatSeen = (message: IMessage) => { 
        let messageId = message.msgBody;

        let index = this.conversationInstance.findIndex( (candidate) => { 
            return candidate.id === messageId; 
        });   
        
        if (index > -1) {
            this.conversationInstance[index].seen.add(message.fromJid)
            console.log(`[TransportStore] Message ${messageId} seen by mate ${message.fromJid}`)
        }
    }

    ackChat = (msgId:string) => {
        this.storesInstance.creStore.crew.forEach((mate:IMate) => {

            let chatAckMessage:IMessage = EMPTY_MESSAGE;
       
            console.log(`[TransportStore] Ack'ing ${mate.jid} with message ${msgId}`)

            chatAckMessage.fromJid = this.storesInstance.conStore.profile.jid;
            chatAckMessage.toJid  = mate.jid;            
            chatAckMessage.sendStamp = dayjs().format(Global.TIMESTAMP_FORMAT);
            chatAckMessage.type = MSG_TYPE_CHAT_ACK;
            chatAckMessage.msgBody = msgId;

            this.sendMessage(chatAckMessage);

        })
    }    

    clearConversation = () => {
        this.conversationInstance = [];
        this.transportDao.putChat(this.chat);  
    }

    clearData = () => {
        this.currentLocation = Global.LOCATION_UNKNOWN;
        this.conversationInstance = []
        this.messageQueue = []
    }

}

MobX.decorate (
    TransportStore, {
        messageQueue: MobX.observable,
        conversationInstance: MobX.observable,
        messageInstance: MobX.observable,   
        currentLocation: MobX.observable,   
        statusOnline: MobX.observable, 
        
        appendChat: MobX.action,
        clearData: MobX.action,
        markChatSeen: MobX.action,
    }
)
