import {Global} from '../global/Global'
export interface ISettings   {
    latOffset: number,
    longOffset: number,
    refreshInterval: number,
    messageLimit: number,
    locationAge: number,
    locationTimeout: number,
    host: string,
    port:string,
}

export const EMPTY_SETTINGS: ISettings = {
    host: '',
    port:'',
    latOffset: 0,
    longOffset: 0,
    refreshInterval: Global.STATUS_REFRESH_INTERVAL_SECONDS,
    messageLimit: 100,
    locationAge: Global.LOCATION_AGE_SECONDS,
    locationTimeout: Global.LOCATION_TIMEOUT_SECONDS,
}
