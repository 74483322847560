import * as MobX from 'mobx';

import StoresInstance from './StoresInstance';
import { Global } from '../global/Global';
import logo from '../logo-32.png'

export class UiStore {
    storesInstance : StoresInstance;

    messageMode:string = Global.STATUS_HIDDEN;
    messageInstance:string = '';

    hasCamera: string;
    
    locationUpdateTime: any;

    ignoreBrowserNotifications: boolean;
    browserNotificationTitle:string;
    browserNotificationBody:string;
    browserNotificationOptions:any;

    constructor(storesInstance : StoresInstance) {
        this.storesInstance = storesInstance;

        this.hasCamera = Global.STATUS_UNKNOWN_CAMERA;

        this.ignoreBrowserNotifications = false;
        this.browserNotificationTitle = '';
        this.browserNotificationBody = '';      
        this.browserNotificationOptions = {};
    }    

    probeCamera = () => {
        navigator.mediaDevices.getUserMedia({video:true, audio:false})
        .then((stream) => {
            this.hasCamera = Global.STATUS_CAMERA;
        })
        .catch((err) => {
            this.hasCamera = Global.STATUS_NO_CAMERA;
        });
    }

    resetMessage = () => {
        this.messageMode = Global.STATUS_HIDDEN;
        this.messageInstance = '';
    }

    triggerBrowserNotification = (title:string, body:string, tag:string) => {
        if (!this.ignoreBrowserNotifications) {

            console.log(`[UIStore] Triggering browser notification for ${title} - ${body},  ${tag}`);

            this.browserNotificationOptions = {
                //tag: tag,
                body: body,
                icon: logo,
                lang: 'en',
                dir: 'ltr',
                renotify: true,
            }
            this.browserNotificationTitle = title;
        }
    }

    clearBrowserNotification = () => {
        this.browserNotificationOptions = {}
        this.browserNotificationTitle = '';
    }
}

MobX.decorate (
    UiStore, 
    {
        messageMode: MobX.observable,
        messageInstance: MobX.observable,
        hasCamera: MobX.observable,
        locationUpdateTime: MobX.observable,

        ignoreBrowserNotifications: MobX.observable,
        browserNotificationTitle: MobX.observable,
        browserNotificationBody: MobX.observable,
        browserNotificationOptions: MobX.observable,

        resetMessage:MobX.action,
        probeCamera:MobX.action,
        triggerBrowserNotification:MobX.action,
       
    }
)
