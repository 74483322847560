import * as React from 'react';
import * as MobX from 'mobx-react';

import {TransportStore} from'../stores/TransportStore'
import {ConfigStore} from'../stores/ConfigStore'


class TransportAdapter extends React.Component <any, any> {
    readonly traStore : TransportStore;
    readonly conStore : ConfigStore;

    constructor(props: any) {
        super(props);
    
        this.traStore  = this.props.storesInstance.traStore
        this.conStore  = this.props.storesInstance.conStore
    }

    render() {     
        
        this.traStore.connectLocationService()  
        this.traStore.connectTransportService(this.conStore.profile)

        return (null)
    }
}

export default MobX.inject('storesInstance')(MobX.observer(TransportAdapter))