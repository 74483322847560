import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import {Download, Share, View} from 'grommet-icons';
import CustomIconPicker  from '../components/CustomIconPicker';
import Notification  from '../components/Notification';
import {ConfigStore} from'../stores/ConfigStore';
import {UiStore} from'../stores/UiStore';
import { Global } from '../global/Global';

const QRCode = require('qrcode.react');
const CryptoJS = require("crypto-js");
    
class Profile extends React.Component <any, any> {
    readonly conStore : ConfigStore;
    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.conStore  = this.props.storesInstance.conStore  
        this.uiStore  = this.props.storesInstance.uiStore;

        this.state = {
            showIconModal:false,
            showShareModal: false,
            fileName: '',
            QRString:'',
            revealPin: false,
        };     
    }

    toggleIconModal = () => {
        this.setState(prevState => ({
            showIconModal: !prevState.showIconModal
        }));
    }   

    iconChange = (icon) => {
        let tempProfile = this.conStore.profile;

        if (tempProfile.icon !== icon) {
            tempProfile.icon = icon;
            this.conStore.profile = tempProfile;
            this.toggleIconModal();
        }
    }

    colourChange = (colour, event) => {
        let tempProfile = this.conStore.profile;
        tempProfile.iconColour = colour.hex;
        this.conStore.profile = tempProfile;
    }

    toggleShareModal = () => {
        this.setState(prevState => ({
            showShareModal: !prevState.showShareModal
        }));
    } 

    doShare = () => {
        let profile = {jid:this.conStore.profile.jid, name:this.conStore.profile.name, key:this.conStore.profile.key };
        let pin = Math.floor(Math.random() * 10000).toString().padStart(4,'0'); 

        let plaintext = JSON.stringify(profile);
        let ciphertextBytes = CryptoJS.AES.encrypt(plaintext, "K" + pin );       
        let ciphertextString = ciphertextBytes.toString()

        this.setState({
            QRPin: pin,
            QRString: ciphertextString,
        });

        this.toggleShareModal();
    }

    toggleRevealPin = () => {
        this.setState(prevState => ({
            revealPin: !prevState.revealPin
        }));
    }

    doDownloadQR = () => {
        let QRCodeCanvas = (document.getElementById("QRCodeCanvas") !) as HTMLCanvasElement;
        var link = document.createElement('a');
        link.textContent = 'download image';
        link.href = QRCodeCanvas.toDataURL("image/png");
        link.download = 'wtfru-QR-' + this.state.QRPin + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  
    }

    render() {

        return (
            <Grommet.Box flex align='start' justify='start' pad="medium" >
                    <Grommet.Box direction="row" fill="horizontal" gap="medium" justify="between" margin={{bottom:"small"}}>
                        <Grommet.Heading margin="none">Profile </Grommet.Heading>

                        <Grommet.Box direction="row" gap="xsmall" justify="end">
                            <Grommet.Button icon={<Share color='status-ok' />} onClick={this.doShare} />
                        </Grommet.Box>
                    </Grommet.Box>
                <Grommet.Form
                    value={this.conStore.profile}
                    onChange={nextValue => {
                        // for some reason this causes the change state warning!! Suspect strange goings-on inside grommet...
                        this.conStore.profile = nextValue;               
                    }}
                    onSubmit={event => {
                            this.conStore.saveProfile();
                            this.uiStore.messageMode = Global.STATUS_OK;
                            this.uiStore.messageInstance = "Profile saved.";
                        }
                    }
                    >
                    <Grommet.Box border pad="small" margin={{bottom:"small"}}>
                        <Grommet.Text color="dark-6" wordBreak="break-all">ID: {this.conStore.profile.jid}</Grommet.Text>
                    </Grommet.Box>
                   
                    <Grommet.FormField name="name" htmlFor="name-id" label="Name (optional)">
                        <Grommet.TextInput id="name-id" name="name" placeholder="Your name" />
                    </Grommet.FormField>    

                    <Grommet.FormField name="email" htmlFor="email-id" label="Email Address (optional, for mailing list subscription)">
                                <Grommet.TextInput id="email-id" name="email"  type="email" placeholder="Your email address" />
                    </Grommet.FormField>  
                    
                    <Grommet.Box pad="medium">
                        <Grommet.Text >Map Icon</Grommet.Text>       
                        <CustomIconPicker defaultValue={this.conStore.profile}  onIconChange={this.iconChange} onColourChange={this.colourChange}/>
                    </Grommet.Box>

                    <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                                >
                        <Grommet.Button label="Reset" />
                        <Grommet.Button type="submit" primary label="Save" />
                    </Grommet.Box>
                </Grommet.Form>
                <Notification />
                     
                {this.state.showShareModal && 
                    <Grommet.Layer responsive={false} position="top" onClickOutside={this.toggleShareModal} onEsc={this.toggleShareModal}  margin={{vertical:"xlarge"}}>
                        <Grommet.Box pad="medium" gap="small" width="medium">
                            <Grommet.Heading level={3} margin="none">
                            Share profile
                            </Grommet.Heading>
                            <Grommet.Box alignSelf="center" pad="large">
                                <QRCode id="QRCodeCanvas" size={198} value={this.state.QRString} />
                                <Grommet.Box  direction="row" align="center" justify="center">
                                    <Grommet.Box border direction="row" margin={{vertical:'medium'}} pad={{vertical:"small", horizontal:"medium"}} align="center" justify="center">                                   
                                        <Grommet.Text size="large" weight="bold">{this.state.revealPin ? this.state.QRPin : "****"}</Grommet.Text>
                                    </Grommet.Box>
                                    <Grommet.Button icon={<View color='status-ok' />} 
                                    onMouseDown={ this.toggleRevealPin } 
                                    onMouseUp={ this.toggleRevealPin }
                                    onTouchStart={ this.toggleRevealPin } 
                                    onTouchEnd={ this.toggleRevealPin }
                                    />                                    
                                </Grommet.Box>
                            </Grommet.Box>
                            <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="between"
                                pad={{ top: "medium", bottom: "small" }}
                            >

                            <Grommet.Button icon={<Download color="status-ok" />} onClick={this.doDownloadQR} />
                            <Grommet.Button
                                label='Close'
                                onClick={this.toggleShareModal}
                                />
                            </Grommet.Box>
                        </Grommet.Box>
                    </Grommet.Layer>
                }                           
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(Profile)))