export const MSG_TYPE_EMPTY_TYPE:string = 'empty_type';
export const MSG_TYPE_UNKNOWN_TYPE:string = 'unknown_type';

export const MSG_TYPE_STATUS_QUERY:string = 'status_query';
export const MSG_TYPE_STATUS_RESPONSE:string = 'status_response';

export const MSG_TYPE_ECHO_QUERY:string = 'echo_query';
export const MSG_TYPE_ECHO_RESPONSE:string = 'echo_response';

export const MSG_TYPE_CHAT:string = 'chat';
export const MSG_TYPE_CHAT_ACK:string = 'chat_ack';

export interface IMessage {
    id: string,
    type: string,
    fromJid: string,
    toJid: string,
    msgBody: string,
    sendStamp: string,
    recvStamp: string,
    seen: Set<string>,
}

export const EMPTY_MESSAGE:IMessage = {
    id: '',
    type: MSG_TYPE_EMPTY_TYPE,
    fromJid: '',
    toJid: '',
    msgBody: '',
    sendStamp: '',
    recvStamp: '',
    seen: new Set(),
}

