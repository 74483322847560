import { set, get, clear } from 'idb-keyval';

import AbstractDAO from './AbstractDAO'
import { IProfile } from '../global/IProfile';
import { ISettings } from '../global/ISettings';

const PROFILE_KEY:string = 'profile';
const SETTINGS_KEY:string = 'settings';

export default class ConfigDAO extends AbstractDAO {

    putProfile = (profile: IProfile) => {   
        set(PROFILE_KEY, profile)
//        .then(() => console.log('It worked!'))
        .catch(err => console.log('Profile save failed!', err));
    }

    getProfile = () => {
        return get(PROFILE_KEY)
    }

    putSettings = (settings: ISettings) => {   
        set(SETTINGS_KEY, settings)
        .catch(err => console.log('Settings save failed!', err));
    }

    getSettings = () => {
        return get(SETTINGS_KEY)
    }

    clearStore = () => {
        clear();
    }

}