import { set, get } from 'idb-keyval';

import AbstractDAO from './AbstractDAO'

const CHAT_KEY:string = 'chat'

export default class TransportDAO extends AbstractDAO {

    getChat = () => {
        return get(CHAT_KEY)
    }

    putChat = (chat: any) => {
        set(CHAT_KEY, chat)
        .catch(err => console.log('Chat save failed!', err));
    }

}