import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
    
class NewPage extends React.Component <any, any> {

    goCreate = () => {
        this.props.history.push("/register")
    }

    goRestore = () => {
        this.props.history.push("/backup")
    }

    render() {

        return (
            <React.Fragment>
                <Grommet.Box flex align='start' justify='start' pad="medium" gap="medium" >
                    <Grommet.Heading margin="none">Welcome!</Grommet.Heading>
                    <Grommet.Text>This is the first time you have opened the app on this device/browser.  You can either create a new account, or restore your profile from a previous backup...</Grommet.Text>

                    <Grommet.Button
                                    primary   
                                    label='Create New Account'
                                    onClick={this.goCreate}
                                    />

                   
                    <Grommet.Button
                                    primary   
                                    label='Restore Profile From Backup'
                                    onClick={this.goRestore}
                                    />
                </Grommet.Box>
            </React.Fragment>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(NewPage)))