import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import { Clear, Link, CircleQuestion} from 'grommet-icons';

import dayjs from 'dayjs';

import {Global} from '../global/Global'
import {UiStore} from'../stores/UiStore'
    
class StatusIcon extends React.Component <any, any> {

    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.uiStore  = this.props.storesInstance.uiStore;
    }

    render() {
        if (!this.props.mate.loc|| !this.props.mate.lastLocUpd) {
            return <Clear color='status-critical'  /> 
        } else {                                
            if (this.props.mate.lastLocUpd) {
                try {
                    let lastUpdate = dayjs(this.props.mate.lastLocUpd);
                    let timeoutThreshold = dayjs().subtract(Global.STATUS_TIMEOUT_SECONDS, 'second');

                    //console.log(`[StatusIcon] ${lastUpdate} > ${timeoutThreshold} = ${lastUpdate.isAfter(timeoutThreshold)}`)
                    
                    if (lastUpdate.isAfter(timeoutThreshold)) {

                        return <Link color='status-ok'  /> 
                    } else {
                        return <CircleQuestion color='status-warning'  /> 
                    }
                } catch (e) {
                    console.log(`[StatusIcon] ${e}`)
                    return <CircleQuestion color='status-warning'  /> 
                } 

            }  else {
                return <Clear color='status-critical'  /> 
            }
        }
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(StatusIcon)))

    