import * as MobX from 'mobx';

import { Global } from '../global/Global';
import StoresInstance from './StoresInstance';
import ConfigDAO from '../dataaccess/ConfigDAO';
import { IProfile, EMPTY_PROFILE } from '../global/IProfile';
import { ISettings, EMPTY_SETTINGS } from '../global/ISettings';
import {IApiResponse} from '../global/IApiResponse'

export enum REGISTRATION_STAGE {
    UNKNOWN = 'unknown',
    INITIAL = 'ínitial',
    REQUESTED = 'requested',
    VERIFIED = 'verified',
}

export class ConfigStore {
    storesInstance : StoresInstance;

    configDao : ConfigDAO;

    profileInstance:IProfile = EMPTY_PROFILE;

    settingsInstance:ISettings = EMPTY_SETTINGS;

    registrationStage:REGISTRATION_STAGE = REGISTRATION_STAGE.INITIAL;

    constructor(storesInstance : StoresInstance) {
        this.storesInstance = storesInstance;
        
        this.configDao = new ConfigDAO();
        
        this.reloadCredentials();

        this.reloadSettings();
    }

    set profile(credentials : IProfile) {
        this.profileInstance = credentials;
    }

    get profile () {        
        return MobX.toJS(this.profileInstance, {recurseEverything:true});
    }

    saveProfile = () => {
        this.configDao.putProfile(this.profile);

        if (this.profile.email) {
                this.storesInstance.apiService.subscribe(this.profile.jid, this.profile.pwd, this.profile.email).then(object => {
        
                    let result:IApiResponse = object as IApiResponse
        
                    if (result) {   
                        if (result.errors && result.errors.length ===0)    {
                            this.storesInstance.uiStore.messageMode = Global.STATUS_OK;
                            this.storesInstance.uiStore.messageInstance = "Email subscription updated";
                        }  else {
                            this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                            this.storesInstance.uiStore.messageInstance = `${result.message}: ${result!.errors![0]}`;
                        }          
                    } else {
                        this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                        this.storesInstance.uiStore.messageInstance = "System error in subscription";
                    }
                }) 
        } else {
            this.storesInstance.apiService.unsubscribe(this.profile.jid, this.profile.pwd).then(object => {
        
                let result:IApiResponse = object as IApiResponse
    
                if (result) {   
                    if (result.errors && result.errors.length ===0)    {
                        this.storesInstance.uiStore.messageMode = Global.STATUS_OK;
                        this.storesInstance.uiStore.messageInstance = "Email subscription removed";
                    }  else {
                        this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                        this.storesInstance.uiStore.messageInstance = `${result.message}: ${result!.errors![0]}`;
                    }          
                } else {
                    this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                    this.storesInstance.uiStore.messageInstance = "System error in remove subscription";
                }
            }) 
        }
    }

    reloadCredentials = () => {
        this.configDao.getProfile().then(result => {
            if (result) {    
                MobX.runInAction(() => {
                    this.profile = result as IProfile;
                })
            } else {           
                this.profile = EMPTY_PROFILE
            }
        })
    }

    set settings(settings : ISettings) {
        this.settingsInstance = settings;
    }

    get settings () {        
        return MobX.toJS(this.settingsInstance, {recurseEverything:true});
    }

    set regStage(registrationStage:REGISTRATION_STAGE) {
        this.registrationStage = registrationStage;
    }

    get regStage () {        
        return this.registrationStage;
    }

    saveSettings = () => {
        this.configDao.putSettings(this.settings);

        this.storesInstance.traStore.connectLocationService();
    }

    reloadSettings = () => {
        this.configDao.getSettings().then(result => {
    
            if (result) {             
                this.settingsInstance = result as ISettings;
                if (this.storesInstance.traStore) {}
            } else {               
                this.settingsInstance = EMPTY_SETTINGS
            }
        })
    }

    clearData = () => {
        this.configDao.clearStore();

        this.profileInstance = EMPTY_PROFILE;

        this.settingsInstance = EMPTY_SETTINGS;
    }

    register = (id:string, pwd: string, email:string) => {
        this.storesInstance.apiService.register(id, pwd, email).then(object => {

            let result:IApiResponse = object as IApiResponse

            if (result) {   
                if (result.errors && result.errors.length ===0)    {
                    this.regStage = REGISTRATION_STAGE.REQUESTED
                }  else {
                    this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                    this.storesInstance.uiStore.messageInstance = `${result.message}: ${result!.errors![0]}`;
                }          
            } else {
                this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                this.storesInstance.uiStore.messageInstance = "System error in registration";
            }
        }) 
    }

    verifyRegistration = (id:string, pwd: string, token:string) => {
        this.storesInstance.apiService.verifyRegistration(id, pwd, token).then(object => {

            let result:IApiResponse = object as IApiResponse

            if (result) {   
                if (result.errors && result.errors.length ===0)    {
                    this.regStage = REGISTRATION_STAGE.VERIFIED
                } else {
                    this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                    this.storesInstance.uiStore.messageInstance = `${result.message}: ${result!.errors![0]}`;
                }         
            } else {
                this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                this.storesInstance.uiStore.messageInstance = "System error completing registration";
            }
        }) 
    }

    deleteRegistration = () => {
        this.storesInstance.apiService.deleteRegistration(this.profile.jid, this.profile.pwd).then(object => {

            let result:IApiResponse = object as IApiResponse

            if (result) {   
                if (result.errors && result.errors.length ===0)    {
                    this.storesInstance.uiStore.messageMode = Global.STATUS_OK;
                    this.storesInstance.uiStore.messageInstance = `${result.message}`;
                } else {
                    this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                    this.storesInstance.uiStore.messageInstance = `${result.message}: ${result!.errors![0]}`;
                }         
            } else {
                this.storesInstance.uiStore.messageMode = Global.STATUS_ERR;
                this.storesInstance.uiStore.messageInstance = "System error completing registration";
            }
        }) 
    }

}

MobX.decorate (
    ConfigStore, 
    {
        profileInstance: MobX.observable,
        settingsInstance: MobX.observable,
        registrationStage: MobX.observable,

        profile: MobX.computed,
        settings: MobX.computed,

        reloadCredentials:MobX.action,
        reloadSettings: MobX.action,
        clearData: MobX.action,
    }
)
