export interface IStatus {
    jid: string,
    loc: string,
    timestamp: string,
}

export const EMPTY_STATUS:IStatus = {
    jid: '',
    loc: '',
    timestamp: '',
}

