import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import { FormClose, StatusGood, StatusWarning } from "grommet-icons";

import {Global} from '../global/Global'
import {UiStore} from'../stores/UiStore'

class Notification extends React.Component <any, any> {
    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.uiStore  = this.props.storesInstance.uiStore;
    } 

    render() {     
        return (

            <React.Fragment>
                {this.uiStore.messageMode !== Global.STATUS_HIDDEN && (
                    <Grommet.Layer
                        position="top"
                        modal={false}
                        margin={{ vertical: "xlarge", horizontal: "small" }}
                        onEsc={this.uiStore.resetMessage}
                        responsive={false}
                        plain
                        full="horizontal"
                    >
                        <Grommet.Box
                            align="center"
                            direction="row"
                            gap="small"
                            justify="between"
                            round="medium"
                            elevation="medium"
                            pad={{ vertical: "xsmall", horizontal: "small" }}
                            background={this.uiStore.messageMode === Global.STATUS_OK ? "status-ok": "status-error" }
                        >
                            <Grommet.Box align="center" direction="row" gap="xsmall">
                                {this.props.mode === Global.STATUS_OK ? <StatusGood /> : <StatusWarning/> }
                                <Grommet.Text>{this.uiStore.messageInstance}</Grommet.Text>
                            </Grommet.Box>
                            <Grommet.Button icon={<FormClose />} onClick={this.uiStore.resetMessage} plain />
                        </Grommet.Box>
                    </Grommet.Layer>
                )}              
            </React.Fragment>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(Notification)))