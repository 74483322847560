import { set, get} from 'idb-keyval';

import AbstractDAO from './AbstractDAO'

const CREW_KEY:string = 'crew'

export default class CrewsDAO extends AbstractDAO {

    getCrew = () => {
        return get(CREW_KEY)
    }

    putCrew = (crew: any) => {
        set(CREW_KEY, crew.slice())
        //.then(() => console.log('Crew saved'))
        .catch(err => console.log('Crew save failed!', err));
    }

}