import {ShutdownHandler} from '../global/Global'

export default class TransportService {
    watchHandle: number = 0;

    constructor() {
        ShutdownHandler.addShutdownHook(()=>{
            console.log("Stopping location watch.");
            this.resetLocationWatch();
        });
    }

    monitorLocation = (success: (position: any) => void,  error: (message: any) => void, positionOptions:PositionOptions) => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
            this.watchHandle = navigator.geolocation.watchPosition(success, error, positionOptions);
        } 



    }  
    
    resetLocationWatch = () => {
        if (this.watchHandle !==0) {
            navigator.geolocation.clearWatch(this.watchHandle); 
        }
    }
}