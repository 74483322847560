import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import './index.css';
import App from './App';
import {storesInstance} from './stores/StoresInstance';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    (<ReactRouter.BrowserRouter basename={"/"}>
        <MobX.Provider storesInstance={storesInstance}>
            <App/>
        </MobX.Provider>
    </ReactRouter.BrowserRouter>)
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

