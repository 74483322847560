
const FORCE_LOCAL = false;

export let Global = {

    DEFAULT_HOST: 'freg.duckdns.org',
    DEFAULT_BOSH_PORT: '5281',

    // @ts-ignore
    DEFAULT_API_HOST:   process.env.NODE_ENV==='production' ||  FORCE_LOCAL===false ?  'freg.duckdns.org' : 'localhost',
    // @ts-ignore
    DEFAULT_API_PORT:   process.env.NODE_ENV==='production' ||  FORCE_LOCAL===false  ? '' : ':5285',
    // @ts-ignore
    DEFAULT_API_SCHEME: process.env.NODE_ENV==='production' ||  FORCE_LOCAL===false  ? 'https' : 'http',

    DEFAULT_JID : "Username",
    DEFAULT_PWD : "Password",

    TIMESTAMP_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSZ",

    FILENAME_TIMESTAMP_FORMAT: "YYYYMMDD-HHmmss",

    XMPP_DISCONNECT_TIMEOUT_SECONDS: 20,

    STATUS_TIMEOUT_SECONDS: 300,

    STATUS_REFRESH_INTERVAL_SECONDS: 30,

    LOCATION_UNKNOWN: 'Location Unknown',

    LOCATION_AGE_SECONDS: 10*60,

    LOCATION_TIMEOUT_SECONDS: 60,

    MAP_WARNING_COLOUR: "#f5bb39",

    UI_COLOURS: new Map([
            ["#a23337","red", ],
            ["#d73e29", "orange-dark", ],
            ["#ef9227","orange", ],
            //["#f5bb39", "yellow",],
            ["#276273", "blue-dark",],
            ["#32a9dd", "cyan",],
            ["#440444", "purple",],
            ["#90278d", "violet", ],
            ["#c057a0", "pink", ],
            ["#006838", "green", ],
            ["#e8e8e8", "white",],
            ["#211c1d", "black",]
    ]),

    STATUS_OK: "ok",
    STATUS_ERR: "err",
    STATUS_HIDDEN: "hidden",        

    STATUS_CAMERA: "Has Camera",
    STATUS_NO_CAMERA: "Has No Camera",
    STATUS_UNKNOWN_CAMERA: "Has Unknown Camera",

    BROWSER_NOTIFICATION_TAG_MSG:"chatmsg",
};

export function camelCaseToDash(str) {
    return str
        .replace(/[^a-zA-Z0-9]+/g, '-')
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/([0-9])([^0-9])/g, '$1-$2')
        .replace(/([^0-9])([0-9])/g, '$1-$2')
        .replace(/-+/g, '-')
        .toLowerCase();
}

export let ShutdownHandler = (function() {    
    var shutdownHooks: Array<() => void> = [];
    var initialised = false;

    function initialise() {
        window.addEventListener("unload", (event) => {
            shutdownHooks.forEach(element => {
                element();
            });
        });

        shutdownHooks.push(()=> {
            console.log("Cleaning up on shutdown...");
        });

        initialised= true;
    };

   
    return {
        addShutdownHook:function (hook: () => void ) {
            if (!initialised) {
                initialise();
            }

            shutdownHooks.push(hook);
        }
    };
  })();

  