import * as XMPP from 'stanza';

import { IProfile } from '../global/IProfile';
import {Global} from '../global/Global'

type MsgCallback = (m: any) => any;

export default class TransportService {
    xmppclient: XMPP.Agent | null = null;


   createClient = (profile:IProfile, host, port) => {       
       this.doDisconnect();

       let hostDomain = Global.DEFAULT_HOST;
       if (host) {
            hostDomain = host;
       }

       let hostPort = Global.DEFAULT_BOSH_PORT;
       if (port) {
        hostPort = port;
       }
       
       //console.log('connecting to https://'+ hostDomain + ':'+ hostPort + '/http-bind')
       //console.log(`id: ${profile.jid}, pwd=${profile.pwd}`)

        this.xmppclient = XMPP.createClient({
            jid: profile.jid + '@' + Global.DEFAULT_HOST,
            password: profile.pwd,
            server: Global.DEFAULT_HOST,

            transports: {
                bosh: 'https://'+ hostDomain + ':'+ hostPort + '/http-bind'
            }
        }); 
    }

    doReconnect = () => {
        
/*         this.xmppclient && this.xmppclient.on('auth:success', () => {
            console.log(`[TransportService] auth:success`) 
        });
        this.xmppclient && this.xmppclient.on('auth:failed', () => {
            console.log(`[TransportService] auth:failed`) 
        });
        this.xmppclient && this.xmppclient.on('bosh:terminate', () => {
            console.log(`[TransportService] bosh:terminate`) 
        });  
        this.xmppclient && this.xmppclient.on('connected', () => {
            console.log(`[TransportService] connected`) 
        });
        this.xmppclient && this.xmppclient.on('disconnected', () => {
            console.log(`[TransportService] disconnected`) 
        });   
        this.xmppclient && this.xmppclient.on('session:prebind', () => {
            console.log(`[TransportService] session:prebind`) 
        });
        this.xmppclient && this.xmppclient.on('session:bound', () => {
            console.log(`[TransportService] session:bound`) 
        });
        this.xmppclient && this.xmppclient.on('session:end', () => {
            console.log(`[TransportService] session:end`) 
        }); 

        this.xmppclient && this.xmppclient.on('message:error', () => {
            console.log(`[TransportService] message:error`) 
        }); 
        this.xmppclient && this.xmppclient.on('message:failed', () => {
            console.log(`[TransportService] message:failed`) 
        });   
        this.xmppclient && this.xmppclient.on('sasl', () => {
            console.log(`[TransportService] sasl`) 
        });        
        this.xmppclient && this.xmppclient.on('sm', () => {
            console.log(`[TransportService] sm`) 
        }); 
        this.xmppclient && this.xmppclient.on('sasl', () => {
            console.log(`[TransportService] sasl`) 
        });      
        this.xmppclient && this.xmppclient.on('stanza:failed', () => {
            console.log(`[TransportService] stanza:failed`) 
        });      
        this.xmppclient && this.xmppclient.on('stream:end', () => {
            console.log(`[TransportService] stream:end`) 
        });      
        this.xmppclient && this.xmppclient.on('stream:error', () => {
            console.log(`[TransportService] stream:error'`) 
        });    */  
                
        this.xmppclient && this.xmppclient.connect() ;        
    }

    doDisconnect() {
        if (this.xmppclient){
            this.xmppclient.on('session:started', () => void {});
            this.xmppclient.on('message', () => void {});
            this.xmppclient.on('session:started', () => void {});
            this.xmppclient.on('message:failed', () => void {});           
            this.xmppclient.on('stanza:failed', () => void {});    

            this.xmppclient.disconnect();

            this.xmppclient = null;
        }
    }

    setHandleOnConnectionFailure = (callback: () => void ) => {
        //force disconnect on any error
        this.xmppclient && this.xmppclient.on('stanza:failed', () => {
            console.log(`[TransportService] stanza failed`)

            callback();   

            this.xmppclient && this.xmppclient.disconnect();

        });     

        //when disconnected, restart after timeout
        this.xmppclient && this.xmppclient.on('bosh:terminate', () => {
            console.log(`[TransportService] bosh:terminate, setting restart timeout`)

            setTimeout(this.doReconnect, Global.XMPP_DISCONNECT_TIMEOUT_SECONDS * 1000);

        });            
    }

    setHandleOnConnect = (callback: () => void ) => {
        this.xmppclient && this.xmppclient.on('session:started', () => {
            console.log(`[TransportService] session started`)
            this.xmppclient && this.xmppclient.sendPresence()
            callback();   
        });
    }

    setHandleOnMessage = (callback: (message: any) => void ) => {
        this.xmppclient && this.xmppclient.on('message', msg => {
            callback(msg);
         });
    };

    setHandleOnChat = (callback: (message: any) => void ) => {
        this.xmppclient && this.xmppclient.on('chat', msg => {
            callback(msg);
         });
    };

    sendMessage= (jid:string, msg:string) => {
        this.xmppclient && this.xmppclient.sendMessage({
            to: `${jid}@${Global.DEFAULT_HOST}`,
            body: msg,
        });
    }


}