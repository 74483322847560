import * as React from 'react';
import * as Grommet from 'grommet';
import { IconPicker} from './icon/IconPicker'

import { CirclePicker } from 'react-color';
import {Global} from '../global/Global'

export default class CustomIconPicker extends React.Component <any, any> {

    iconChangeHandler = (icon) => {
        this.props.onIconChange(icon);
    }

    render() {     

        let buttonIconStyles = {
            color: this.props.defaultValue.iconColour,
        }

        return (
            <Grommet.Box pad="medium" gap="small" width="medium" justify="center" align="center">

                <IconPicker value={this.props.defaultValue.icon} onChange={this.iconChangeHandler} buttonIconStyles={buttonIconStyles} pickerIconStyles={buttonIconStyles}/>
                
                <CirclePicker colors={Array.from(Global.UI_COLOURS.keys())} onChange={this.props.onColourChange}  />
            </Grommet.Box>
        )
  }
}
