import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';

import {ConfigStore} from'../stores/ConfigStore'
import {UiStore} from'../stores/UiStore';
import { Global } from '../global/Global';
import Notification  from '../components/Notification';
    
class Settings extends React.Component <any, any> {
    readonly conStore : ConfigStore;
    readonly  uiStore : UiStore;

    constructor(props: any) {
        super(props);

        this.conStore  = this.props.storesInstance.conStore   
        this.uiStore  = this.props.storesInstance.uiStore;    
    }

       render() {

        return (
            <Grommet.Box flex align='start' justify='start' pad="medium" >
                <Grommet.Heading margin="none">Advanced Settings </Grommet.Heading>
                <Grommet.Form
                    value={this.conStore.settings}
                    onChange={nextValue => {
                        // for some reason this causes the change state warning!! Suspect strange goings-on inside grommet...
                        this.conStore.settings = nextValue;             
                    }}
                    onSubmit={event => {
                            this.conStore.saveSettings();
                            this.uiStore.messageMode = Global.STATUS_OK;
                            this.uiStore.messageInstance = "Settings saved.";
                        }
                    }
                    >

                    <Grommet.FormField name="host" htmlFor="host-id" label="Server">
                        <Grommet.TextInput id="host-id" name="host" />
                    </Grommet.FormField>

                    <Grommet.FormField name="port" htmlFor="port-id" label="Server Port">
                        <Grommet.TextInput id="port-id" name="port" />
                    </Grommet.FormField>                    

                    <Grommet.FormField name="latOffset" htmlFor="latOffset-id" label="Latitude Offset">
                        <Grommet.TextInput id="latOffset-id" name="latOffset" />
                    </Grommet.FormField>

                    <Grommet.FormField name="longOffset" htmlFor="longOffset-id" label="Longitude Offset">
                        <Grommet.TextInput id="longOffset-id" name="longOffset" />
                    </Grommet.FormField>

                    <Grommet.FormField name="refreshInterval" htmlFor="refreshInterval-id" label="Status Refresh Interval (s)">
                        <Grommet.TextInput id="refreshInterval-id" name="refreshInterval" />
                    </Grommet.FormField>     

                    <Grommet.FormField name="messageLimit" htmlFor="messageLimit-id" label="Chat Message Limit">
                        <Grommet.TextInput id="messageLimit-id" name="messageLimit" />
                    </Grommet.FormField>    

                    <Grommet.FormField name="locationAge" htmlFor="locationAge-id" label="Location Service Cache Max Age (s)">
                        <Grommet.TextInput id="locationAge-id" name="locationAge" />
                    </Grommet.FormField>       

                    <Grommet.FormField name="locationTimeout" htmlFor="locationTimeout-id" label="Location Service Timeout (s)">
                        <Grommet.TextInput id="locationTimeout-id" name="locationTimeout" />
                    </Grommet.FormField>                    

                    <Grommet.Box
                                as="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                                >
                        <Grommet.Button label="Reset" />
                        <Grommet.Button type="submit" primary label="Save" />
                    </Grommet.Box>
                </Grommet.Form>
                <Notification />
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(Settings)))