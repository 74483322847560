import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from 'mobx-react';
import * as Grommet from 'grommet';
import {Chat} from 'grommet-icons';
import dayjs from 'dayjs'

import {TransportStore} from'../stores/TransportStore'
import {CrewsStore} from'../stores/CrewsStore'
import {ConfigStore} from'../stores/ConfigStore'
import { IMessage, MSG_TYPE_CHAT } from '../global/IMessage';
import { IMate, EMPTY_MATE } from '../global/IMate';
import {Global, camelCaseToDash} from '../global/Global'

const TimeLapsed = require('timelapsed');
    
class ChatBox extends React.Component <any, any> {

    readonly traStore : TransportStore;
    readonly creStore : CrewsStore;
    readonly conStore : ConfigStore;

    constructor(props: any) {
        super(props);

        this.state = {
            messageText : '',
        };
    
        this.traStore  = this.props.storesInstance.traStore;
        this.creStore  = this.props.storesInstance.creStore;
        this.conStore  = this.props.storesInstance.conStore;
    }

    getMateFromMessage = (message:IMessage) => {
        let mate:IMate = EMPTY_MATE;

        let jid = message.fromJid.split("/")[0];

        if (jid === this.conStore.profile.jid) {
            mate = this.conStore.profile
            mate.name = "Me"
            mate.nick = "Me"
        } else {
            let potentialMate:any = this.creStore.getMateByJid(message.fromJid.split("/")[0]);
            mate = potentialMate ? potentialMate : {...EMPTY_MATE, nick: 'Unknown', name:'Unknown',
            }
        }

        return mate;
    }

    onMessageChange = (value) => {
        this.setState(prevState => ({
            messageText: value,
        }));  
    }

    sendMessage = () => {
        if (this.state.messageText) {
            let message = this.traStore.createMessage(MSG_TYPE_CHAT, this.state.messageText);

            this.creStore.crew.forEach((mate) =>{
                this.traStore.sendMessage(message, mate.jid);
            });

            message.recvStamp = dayjs().format(Global.TIMESTAMP_FORMAT);

            this.traStore.appendChat(message);

            this.setState({
                messageText: '',
            }); 
        }
    }

    render() {

        return (
            <Grommet.Box width="xxlarge" justify="start"> 
                <Grommet.Main  background="#ffffff" direction="row" fill="horizontal" justify="between" margin={{bottom:"small"}} >
                    <Grommet.Box flex align='start' pad="medium" >
                        {(this.traStore.chat.length === 0) &&
                            <Grommet.Text weight="bold" margin="none">No messages yet... </Grommet.Text>
                        }
                        {this.traStore.chat.map((message: IMessage, index) => (
                            <Grommet.Box key={index} alignSelf={this.getMateFromMessage(message).nick === "Me" ? "end": "start"}    >
                                <Grommet.Box pad="small" direction={this.getMateFromMessage(message).nick === "Me" ? "row-reverse": "row"}  >
                                    <Grommet.Box border round="small" pad="xxsmall">
                                        <Grommet.Text color={ this.getMateFromMessage(message).iconColour} size="large" >
                                            <span className={`fas ${camelCaseToDash(this.getMateFromMessage(message).icon)}`}/>
                                        </Grommet.Text>
                                    </Grommet.Box>
                                    <Grommet.Text weight="bold" alignSelf="center" className={this.getMateFromMessage(message).nick === "Me" ? "myMessageLabel": "theirMessageLabel"} >{this.creStore.getMateLabel(this.getMateFromMessage(message))}</Grommet.Text>
                                    <Grommet.Text size="small" alignSelf="center" margin={{top:"3px"}} className={this.getMateFromMessage(message).nick === "Me" ? "myMessageLabel": "theirMessageLabel"}>({message.recvStamp ? TimeLapsed.fromDateString(message.recvStamp ) : "Don't know when..."})</Grommet.Text>
                                </Grommet.Box>
                                <Grommet.Box border={{color:this.getMateFromMessage(message).iconColour}}  background="#FFFFFF" pad="small" 
                                    className={this.getMateFromMessage(message).nick === "Me" ? "myMessageBubble": "theirMessageBubble"}                             
                                >
                                    {message.msgBody ? message.msgBody : "<empty>"}
                                </Grommet.Box>
                            </Grommet.Box>
                        ))}
                    </Grommet.Box>
                </Grommet.Main>
                <Grommet.Footer margin={{horizontal:"medium"}} background="#ffffff" border round="large" direction="row" justify="between">
                    <Grommet.TextInput plain id="msgBody" name="msgBody" reverse value={this.state.messageText} onChange={({ target: { value } }) => this.onMessageChange(value)}/>
                    <Grommet.Button icon={<Chat color='neutral-1'/>}  onClick={this.sendMessage}/>
                </Grommet.Footer> 
            </Grommet.Box>
        )
    }
}

export default MobX.inject('storesInstance')(ReactRouter.withRouter(MobX.observer(ChatBox)))

    