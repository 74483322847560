import {TransportStore} from './TransportStore';
import {ConfigStore} from './ConfigStore';
import {CrewsStore} from './CrewsStore';
import {UiStore} from './UiStore';
import {ApiService} from '../dataaccess/ApiService';

export  default class StoresInstance {

    apiService: ApiService;
    traStore : TransportStore;
    conStore : ConfigStore;
    creStore : CrewsStore;
    uiStore : UiStore;

    constructor() {
        //order is important - config, API, transport, then others
        this.apiService = new ApiService();
        this.conStore = new ConfigStore(this)
        this.traStore = new TransportStore(this)

        this.creStore = new CrewsStore(this)
        this.uiStore = new UiStore(this)

    }
}

let storesInstance = new StoresInstance()

export {storesInstance};